import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axios.service';
// Define the async thunk for fetching the dashboard data
export const dashboard = createAsyncThunk('dashboard/fetchDashboard', async ({ startDate, endDate }, thunkAPI) => {
  try {
    // Make the dashboard request with the Authorization header
    const response = await axios.get(`/api/admindashboard`, {
      params: { startDate, endDate }
    });
    // Return the data if the response is successful
    if (response && response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue({ message: 'No data in response' });
    }
  } catch (error) {
    if (error.response) {
      // If server responded with a status other than 2xx
      return thunkAPI.rejectWithValue(error.response.data);
    } else if (error.request) {
      // If the request was made but no response was received
      return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
    } else {
      // If something else went wrong
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const chart = createAsyncThunk('dashboard/fetchChart', async (_, thunkAPI) => {
  try {

    // Make the dashboard request with the Authorization header
    const response = await axios.get(`/api/chart`);

    // Return the data if the response is successful
    if (response && response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue({ message: 'No data in response' });
    }
  } catch (error) {
    if (error.response) {
      // If server responded with a status other than 2xx
      return thunkAPI.rejectWithValue(error.response.data);
    } else if (error.request) {
      // If the request was made but no response was received
      return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
    } else {
      // If something else went wrong
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const RevenueChart = createAsyncThunk('dashboard/fetchRevenue', async (_, thunkAPI) => {
  try {

    // Make the dashboard request with the Authorization header
    const response = await axios.get(`/api/stakeHolderPaymentChart`);
    // Return the data if the response is successful
    if (response && response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue({ message: 'No data in response' });
    }
  } catch (error) {
    if (error.response) {
      // If server responded with a status other than 2xx
      return thunkAPI.rejectWithValue(error.response.data);
    } else if (error.request) {
      // If the request was made but no response was received
      return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
    } else {
      // If something else went wrong
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

// Define the slice for managing dashboard data
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    loading: true,

    data: null,
    chat: null,

    error: null,
    status: 'idle',
    startDate: '',
    endDate: '',
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    startDateQuery: (state, action) => {
      state.startDate = action.payload;
    },
    endDateDateQuery: (state, action) => {
      state.endDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(dashboard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(dashboard.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload; // Store data in the `data` field
      })
      .addCase(dashboard.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(chart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(chart.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.chat = action.payload; // Store data in the `data` field
      })
      .addCase(chart.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(RevenueChart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(RevenueChart.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.data = action.payload; // Store data in the `data` field
      })
      .addCase(RevenueChart.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
  },
});

export const { clearError, startDateQuery, endDateDateQuery } = dashboardSlice.actions;

export default dashboardSlice.reducer;
