import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../components/DataTable';
import { MdCheckCircle } from 'react-icons/md'; // Importing icons

import { fetchAgent, setCurrentPage, setPerPage, setSearchQuery, toggleSelectItem, setSortConfig, setActiveQuery, setStartDateQuery, setEndDateDateQuery, regAgent } from '../../redux/authenticated/enforcerSlice';
import Button from '../../components/Button';
import { useState } from 'react';
import ConfirmModal from '../../components/ConfirmModal';
import { approve } from '../../redux/authenticated/verification';
import { useNavigate } from 'react-router-dom';

import { Spinal } from '../../assets/icons/extIcons';


const Enforcer = () => {
  document.title = "e-Ticketing | Remitter";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, sortConfig, searchQuery = 'pending', currentPage, perPage, totalPages, selectedItems, loading, active, startDate,
    endDate } = useSelector(state => state.enforcer);

  const [toggleModal, setToggleModal] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [idType, setIdType] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [address, setAddress] = useState('');
  const [role] = useState('enforcer');
  const [route, setRoute] = useState('');
  const [guarantorsName, setGuarantorFullName] = useState('');
  const [guarantorsPhone, setGuarantorPhone] = useState('');
  const [guarantorsAddress, setGuarantorAddress] = useState('');
  const [guarantorsIDType, setIdTypeGuarantor] = useState('');
  const [frontGuarantor, setGuarantorFront] = useState(null);
  const [backGuarantor, setGuarantorBack] = useState(null);
  const [front, setFront] = useState(null);
  const [back, setBack] = useState(null);
  const [errors, setErrors] = useState('');

  const tableHeaders = [
    { key: 'fullName', label: 'Full Name' },
    { key: 'userCode', label: 'Unique ID' },
    { key: 'phone', label: 'Phone' },
    { key: 'email', label: 'Email' },
    { key: 'registrationStatus', label: 'Status' },
    { key: 'actions', label: 'Actions' }, // New column for actions
  ];

  // Render buttons for status
  const renderStatusButton = (status) => {
    let buttonStyle = '';
    let buttonText = '';

    switch (status) {
      case 'approved':
        buttonStyle = 'text-dim-green'; // Green for approved
        buttonText = 'Approved';
        break;
      case 'pending':
        buttonStyle = 'text-yellow'; // Gray for pending
        buttonText = 'Pending';
        break;
      case 'suspended':
        buttonStyle = 'text-red'; // Red for suspended
        buttonText = 'Suspended';
        break;
      default:
        buttonStyle = 'text-black'; // Default color if no match
        buttonText = 'Unknown';
    }

    return <button className={`py-1 rounded ${buttonStyle}`}>{buttonText}</button>;
  };

  const handleApprove = (_e) => {
    dispatch(approve(_e)).then(res => {
      if (res.payload !== 200) {
        return false
      } else {
        dispatch(fetchAgent({
          currentPage, perPage, searchQuery, active, startDate,
          endDate
        }));
      }
    }).catch(err => {
      return err
    });
  };


  // Action buttons for each row
  const renderActionButtons = (item) => (
    <div className="flex space-x-2">
      {item.registrationStatus !== 'approved' &&
        <button
          title='Approve'
          className={`bg-dim-green text-white px-3 py-1 rounded`}
          onClick={() => handleApprove(item._id)}
        >
          <MdCheckCircle size={20} />
        </button>
      }
    </div>
  );

  const handleDetail = (_e) => {
    navigate(`${window.location.pathname === '/' ? 'motorists ' : window.location.pathname}/details`, { state: _e });
  }

  // Row rendering function
  const renderRow = (item, showAll) => (
    <>
      <td className="px-4 py-2 underline" onClick={() => handleDetail(showAll)}>{item.fullName}</td>
      <td className="px-4 py-2">{item.userCode}</td>
      <td className="px-4 py-2">{item.phone}</td>
      <td className="px-4 py-2">{item.email.id}</td>
      <td className="px-4 py-2">{renderStatusButton(item.registrationStatus)}</td>
      <td className="px-4 py-2">{renderActionButtons(item)}</td>
    </>
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(regAgent({ email, password, fullName, phone, idType, maritalStatus, address, front, back, route, role, guarantorsName, guarantorsPhone, guarantorsAddress, guarantorsIDType, frontGuarantor, backGuarantor })).then(res => {
      setErrors(res.payload)
      if (res.payload !== 201) {
        return false
      } else {
        setToggleModal(false)
        dispatch(fetchAgent({
          currentPage, perPage, searchQuery, active, startDate,
          endDate
        }));
      }
    }).catch(err => {
      return err
    });
  }

  const [step, setStep] = useState(1); // state to track the current step

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  return (
    <div className="agents mt-16">
      <div className='flex justify-between'>
        <h3 className='font-semibold text-3xl'>New Enforcer</h3>

        <div className='w-40'>
          <button onClick={() => setToggleModal(prevState => !prevState)} className={'bg-blue rounded-md p-4 text-white w-full uppercase text-sm font-medium'}>
            Create Enforcer
          </button>
        </div>
        <ConfirmModal show={toggleModal} onClose={() => setToggleModal(false)}>
          <form onSubmit={handleSubmit}>
            {loading && !errors}
            {errors && <p className="text-[#FF4C4C] text-sm">{errors?.message}</p>}
            {step === 1 && (
              <div>
                <h2 className="text-xl font-bold mb-4">Step 1: Personal Information</h2>
                <div className="flex justify-center space-x-2 mb-6 mt-8">
                  <div className='text-left'>
                    <input
                      required
                      placeholder='Email'
                      type="email"
                      autoComplete="off"
                      className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    ></input>
                  </div>
                  <div className='text-left'>
                    <input
                      required
                      placeholder='Password'
                      type="password"
                      autoComplete="off"
                      className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    ></input>
                  </div>
                </div>
                <div className="flex justify-center space-x-2 mb-6 mt-8">
                  <div className='text-left'>
                    <input
                      required
                      placeholder='Full Name'
                      type="text"
                      autoComplete="off"
                      className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                      onChange={(e) => setFullName(e.target.value)}
                      value={fullName}
                    ></input>
                  </div>
                  <div className='text-left'>
                    <input
                      required
                      placeholder='Phone'
                      type="tel"
                      autoComplete="off"
                      className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    ></input>
                  </div>
                </div>
                <div className="flex justify-center space-x-2 mb-6 mt-8">
                  <div className='text-left'>
                    <input
                      required
                      placeholder='Address'
                      type="text"
                      autoComplete="off"
                      className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    ></input>
                  </div>
                  <div className='text-left'>
                    <input
                      required
                      placeholder='Route'
                      type="text"
                      autoComplete="off"
                      className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                      value={route}
                      onChange={(e) => setRoute(e.target.value)}
                    ></input>
                  </div>
                </div>
                <div className="flex justify-center space-x-2 mb-6 mt-8">
                  <div className='text-left w-full'>
                    <select
                      autoComplete="off"
                      className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white w-full"
                      onChange={(e) => setMaritalStatus(e.target.value)}
                      value={maritalStatus}
                    >
                      <option value="">Marital Status</option>
                      <option value="single">Single</option>
                      <option value="married">Married</option>
                      <option value="divorced">Divorced</option>
                    </select>
                  </div>
                  <div className='text-left w-full'>
                    <select
                      autoComplete="off"
                      className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white w-full"
                      onChange={(e) => setIdType(e.target.value)}
                      value={idType}
                    >
                      <option value="">Type of ID</option>
                      <option value="nin">NIN</option>
                      <option value="business-id">Business ID</option>
                      <option value="drivers-license">Drivers License</option>
                    </select>
                  </div>
                </div>
                <div className="flex justify-center space-x-2 mb-6 mt-8">
                  <div className='text-left'>
                    <label className="text-light-black text-sm">Upload Front ID image</label>
                    <input
                      placeholder='Upload Front ID image'
                      type="file"
                      accept='image/jpeg, image/png, image/webp'
                      autoComplete="off"
                      name='front'
                      className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white text-xs w-full"
                      onChange={(e) => setFront(e.target.files[0])}
                    ></input>
                  </div>
                  <div className='text-left'>
                    <label className="text-light-black text-sm">Upload back ID image</label>
                    <input
                      type="file"
                      accept='image/jpeg, image/png, image/webp'
                      name='back'
                      autoComplete="off"
                      className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white text-xs w-full"
                      onChange={(e) => setBack(e.target.files[0])}
                    ></input>
                  </div>
                </div>
              </div>
            )}

            {step === 2 && (
              <div>
                <h2 className="text-xl font-bold mb-4">Step 2: Guarantor's Information</h2>
                <div className="flex justify-center space-x-2 mb-6 mt-8">
                  <div className='text-left'>
                    <input
                      required
                      placeholder='Full Name'
                      type="text"
                      autoComplete="off"
                      className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                      onChange={(e) => setGuarantorFullName(e.target.value)}
                      value={guarantorsName}
                    ></input>
                  </div>
                  <div className='text-left'>
                    <input
                      required
                      placeholder='Phone'
                      type="tel"
                      autoComplete="off"
                      className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                      onChange={(e) => setGuarantorPhone(e.target.value)}
                      value={guarantorsPhone}
                    ></input>
                  </div>
                </div>
                <div className="flex justify-center space-x-2 mb-6 mt-8">
                  <div className='text-left'>
                    <input
                      required
                      placeholder='Address'
                      type="text"
                      autoComplete="off"
                      className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                      onChange={(e) => setGuarantorAddress(e.target.value)}
                      value={guarantorsAddress}
                    ></input>
                  </div>
                  <div className='w-full'>
                    <select
                      autoComplete="off"
                      className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                      onChange={(e) => setIdTypeGuarantor(e.target.value)}
                      value={guarantorsIDType}
                    >
                      <option value="">Type of ID</option>
                      <option value="nin">NIN</option>
                      <option value="business-id">Business ID</option>
                      <option value="drivers-license">Drivers License</option>
                    </select>
                  </div>
                </div>
                <div className="flex justify-center space-x-2 mb-6 mt-8">
                  <div className='text-left'>
                    <label className="text-light-black text-sm">Upload Front ID image</label>
                    <input
                      placeholder='Upload Front ID image'
                      type="file"
                      autoComplete="off"
                      accept='image/jpeg, image/png, image/webp'
                      name='front'
                      className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white text-xs w-full"
                      onChange={(e) => setGuarantorFront(e.target.files[0])}
                    ></input>
                  </div>
                  <div className='text-left'>
                    <label className="text-light-black text-sm">Upload back ID image</label>
                    <input
                      type="file"
                      name='back'
                      accept='image/jpeg, image/png, image/webp'
                      autoComplete="off"
                      className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white text-xs w-full"
                      onChange={(e) => setGuarantorBack(e.target.files[0])}
                    ></input>
                  </div>
                </div>
              </div>
            )}

            <div className="flex justify-between mt-6 space-x-3">
              {step > 1 && (
                <input onClick={prevStep} type='button' className='cursor-pointer text-center rounded-2xl p-2 text-white w-full my-4 h-12 bg-graey' disabled={loading ? true : false} value={'Previous'} />
              )}
              {step < 2 && (
                <input type='button' onClick={nextStep} className='cursor-pointer text-center rounded-2xl p-2 text-white w-full my-4 h-12 bg-blue' disabled={loading ? true : false} value={'Next'} />
              )}
              {step === 2 && (
                <Button disabled={loading ? true : false} title={loading ? <div role="status" className='flex justify-center'> <Spinal /> <span className="sr-only">Loading...</span> </div> : 'Submit'} type={'submit'}></Button>
              )}
            </div>
          </form>
        </ConfirmModal>
      </div>
      <div className="mt-5">
        <div className="overflow-x-auto">
          <DataTable pending={true} fetchData={fetchAgent} setCurrentPage={setCurrentPage} setPerPage={setPerPage} setSearchQuery={setSearchQuery} toggleSelectItem={toggleSelectItem} setSortConfig={setSortConfig} setActiveQuery={setActiveQuery} setStartDateQuery={setStartDateQuery} setEndDateDateQuery={setEndDateDateQuery} tableHeaders={tableHeaders} renderRow={renderRow} selectKey={fetchAgent} data={data} sortConfig={sortConfig} searchQuery={searchQuery} currentPage={currentPage} perPage={perPage} totalPages={totalPages} selectedItems={selectedItems} loading={loading} active={active} startDate={startDate} endDate={endDate} showPagination={true} isNew={false} />
        </div>
      </div>
    </div>
  );
};

export default Enforcer;
