import { isRole } from '../../routerGuard/UserRole';
import Registrar from './Motorist';
import ManageEnforcer from './ScanRecord';
import ManageRemitter from './Transaction';
import Dashboard from './Dashboard';
import Union from './AllocationDetail';

const Home = () => {
  const user = isRole();
  return (
    <div>
      {(user.agentRole === 'registrar' || user.department === 'registrar-manager') && <Registrar />}
      {user.department === 'enforcer-manager' && <ManageEnforcer />}
      {user.department === 'remitter-manager' && <ManageRemitter />}
      {(user.role === 'admin' || user.role === 'super-admin') && <Dashboard />}
      {user.role === 'union' && <Union type={user.union} pack={user.unionRole}/>}
    </div>
  )

}

export default Home;