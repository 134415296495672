import { MdOutlineDashboard, MdOutlineAdminPanelSettings } from 'react-icons/md';
import { SiGoogletagmanager } from 'react-icons/si';
import { GiUnionJack} from 'react-icons/gi';
import { FaCashRegister } from 'react-icons/fa';
import { IoIosTrendingDown } from 'react-icons/io';
import { RiRegisteredFill } from 'react-icons/ri';
import { isRole } from '../routerGuard/UserRole';

// eslint-disable-next-line
import { LuAlertOctagon, LuScan, LuBus, LuCog, LuHistory, LuMonitor, LuPlus, LuReceipt, LuUser, LuUserCheck, LuUserCog, LuUsers2, LuWallet } from "react-icons/lu";
const Navigation = () => {
  const { role } = isRole();
  const { department } = isRole();

  return [
    {
      key: 'dashboard',
      label: 'Dashboard',
      path: '/',
      fullPath: '/',
      icon: <MdOutlineDashboard />
    },
    {
      key: 'admins',
      label: 'Admins',
      path: '/admins',
      fullPath: '/admins',
      role: ['admin', 'manager', 'union', 'user'].includes(role),
      icon: <MdOutlineAdminPanelSettings />,
    },
    {
      key: 'managers',
      label: 'Managers',
      path: '/managers',
      fullPath: '/managers',
      role: ['manager', 'union', 'user'].includes(role),
      icon: <SiGoogletagmanager />,
      subLinks: [
        { label: 'New Manager', path: '/newmanager', fullPath: '/newmanager', icon: <SiGoogletagmanager /> },
      ]
    },
    {
      key: 'remitters',
      label: 'Remitters',
      path: '/remitters',
      fullPath: '/remitters',
      role: ['union', 'user'].includes(role),
      department: ['registrar-manager', 'enforcer-manager'].includes(department),
      icon: <FaCashRegister />,
      subLinks: [
        { label: 'New Remitter', path: '/newremitter', fullPath: '/newremitter', icon: <FaCashRegister /> },
      ]
    },
    {
      key: 'enforcers',
      label: 'Enforcers',
      path: '/enforcers',
      fullPath: '/enforcers',
      role: ['union', 'user'].includes(role),
      department: ['registrar-manager', 'remitter-manager'].includes(department),
      icon: <IoIosTrendingDown />,
      subLinks: [
        { label: 'New Enforcer', path: '/newenforcer', fullPath: '/newenforcer', icon: <IoIosTrendingDown /> },
      ]
    },
    {
      key: 'registrars',
      label: 'Registrars',
      path: '/registrars',
      fullPath: '/registrars',
      role: ['union', 'user'].includes(role),
      department: ['enforcer-manager', 'remitter-manager'].includes(department),
      icon: <RiRegisteredFill />,
      subLinks: [
        { label: 'New Registrar', path: '/newregistrar', fullPath: '/newregistrar', icon: <RiRegisteredFill /> },
      ]
    },
    {
      key: 'unions',
      label: 'Unions',
      path: '/unions',
      fullPath: '/unions',
      role: ['admin', 'manager', 'union', 'user'].includes(role),
      icon: <GiUnionJack />
    },
    {
      key: 'motorists',
      label: 'Motorists',
      path: 'motorists',
      fullPath: '/motorists',
      role: ['union'].includes(role),
      department: ['enforcer-manager', 'remitter-manager', 'registrar-manager'].includes(department),
      icon: <LuBus />,
      subLinks: [
        { label: 'New Motorist', path: '/newmotorist', fullPath: '/newmotorist', icon: <LuBus /> },
      ]
    },
    {
      key: 'penalty',
      label: 'Penalty',
      path: 'penaltyHistory',
      fullPath: '/penaltyHistory',
      role: ['manager', 'union', 'user'].includes(role),
      icon: <LuAlertOctagon />,
      subLinks: [
        { label: 'Create Penalty', path: '/penalty', fullPath: '/penalty', icon: <LuAlertOctagon /> },
      ]
    },
    {
      key: 'subscriptions',
      label: 'Subscriptions',
      path: 'subscriptions',
      fullPath: '/subscriptions',
      role: ['manager', 'union', 'user'].includes(role),
      icon: <LuWallet />
    },
    {
      key: 'scan',
      label: 'Scan Record',
      path: 'scan',
      fullPath: '/scan',
      role: ['union', 'user'].includes(role),
      department: ['enforcer-manager', 'remitter-manager', 'registrar-manager'].includes(department),
      icon: <LuScan />
    },
    {
      key: 'transaction',
      label: 'Transaction',
      path: 'transaction',
      fullPath: '/transaction',
      role: ['union', 'user'].includes(role),
      department: ['enforcer-manager', 'remitter-manager', 'registrar-manager'].includes(department),
      icon: <LuHistory />
    },
    {
      key: 'allocation',
      label: 'Allocation',
      path: 'allocation',
      fullPath: '/allocation',
      role: ['admin','manager', 'union', 'user'].includes(role),
      icon: <LuReceipt />
    },
  ]

};

export default Navigation();

