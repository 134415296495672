import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BiEdit } from "react-icons/bi";
import { useLocation } from 'react-router-dom';
import { isRole } from '../../routerGuard/UserRole';

import { fetchProfile, fetchUpdateProfile } from '../../redux/authenticated/profileSlice';
import Button from '../../components/Button';

const ProfilePage = () => {
  const dispatch = useDispatch();
  const location = useLocation(); // Access the location object
  const { role } = isRole();

  const [profileData, setData] = useState({});
  const [updated, setUpdated] = useState(false)
  const data = updated ? profileData : location.state ?? profileData;

  const [info, setInfo] = useState({
    userID: {
      id: data?.userID ? data?.userID._id : data._id,
      fullName: '',
      phone: '',
      address: '',
      maritalStatus: ''
    }
  });

  const { status, error } = useSelector((state) => state.profile);
  const [err, setErr] = useState({});

  useEffect(() => {
    dispatch(fetchProfile()).then(res => {
      if (res?.payload) {
        setData(res.payload);
      }
    });
  }, [dispatch]);


  // State to track whether the form is in edit mode
  const [isEditing, setIsEditing] = useState(false);

  // Toggle edit mode
  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update info.userID based on the input field name
    setInfo((prevInfo) => ({
      ...prevInfo,
      userID: {
        ...prevInfo.userID,
        [name]: value
      }
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsEditing(false);
    // Handle dispatch or API call to save data

    // Dispatch action to update profile
    dispatch(fetchUpdateProfile(info.userID)).then((response) => {
      setErr(response.payload);

      if (response?.payload) {
        setUpdated(true)
        setData({
          ...data,
          userID: response.payload.userID
        }); // Update data after successful save
      }
    });
  };

  const isLoading = status === 'loading';
  const hasError = status === 'failed';
  const hasSuccess = status === 'successfull';

  return (
    <div className="min-h-screen py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 w-full max-w-4xl mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-blue-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:rotate-6 sm:rounded-lg"></div>
        <div className="relative bg-white shadow-lg sm:rounded-lg p-6">
          {/* Profile Section */}

          {isLoading && !hasError}
          {hasError && <p className="text-[#FF4C4C] text-sm">{error?.message ?? err?.message}</p>}
          {hasSuccess && <p className="text-green-600 text-sm">{err?.message}</p>}
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center">
              <img className="w-24 h-24 rounded-full border-4 border-gray-200" src={data?.portraitSelfie?.url || 'https://via.placeholder.com/100'} alt="Profile" />
              <div className="ml-4">
                <h2 className="text-2xl font-semibold">{data?.userID ? data?.userID?.fullName : data?.fullName ?? info?.userID?.fullName}</h2>
                <p className="text-gray-500">{data?.userID ? data.userID?.email?.id : data?.email?.id ?? info?.userID?.email}</p>
              </div>
            </div>
            {['super-admin', 'admin'].includes(role) &&
              <div onClick={handleEditClick} className="cursor-pointer">
                <BiEdit size={24} />
              </div>
            }
          </div>

          {/* Editable Form Section */}
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label className="block text-gray-700">Full Name</label>
                <input
                  type="text"
                  name="fullName"
                  placeholder={data?.userID ? data?.userID?.fullName : data?.fullName}
                  onChange={handleChange}
                  className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 ${isEditing ? 'bg-white' : 'bg-gray-100'}`}
                  readOnly={!isEditing}
                />
              </div>
              <div>
                <label className="block text-gray-700">Marital Status</label>
                <input
                  type="text"
                  name="maritalStatus"
                  placeholder={data?.maritalStatus ?? '***'}
                  onChange={handleChange}
                  className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 bg-gray-100`}
                  readOnly={!isEditing}
                />
              </div>
              <div>
                <label className="block text-gray-700">Phone</label>
                <input
                  type="text"
                  name="phone"
                  placeholder={data?.userID ? '0' + data?.userID?.phone : '0' + data?.phone}
                  onChange={handleChange}
                  className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 ${isEditing ? 'bg-white' : 'bg-gray-100'}`}
                  readOnly={!isEditing}
                />
              </div>
              <div>
                <label className="block text-gray-700">Address</label>
                <input
                  type="text"
                  name="address"
                  placeholder={data?.userID ? data?.address : '***'}
                  onChange={handleChange}
                  className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 ${isEditing ? 'bg-white' : 'bg-gray-100'}`}
                  readOnly={!isEditing}
                />
              </div>
            </div>
            <div className="mt-6 text-right w-40 ml-auto flex">
              {isEditing && (
                <Button type="submit" title={'Save'}>
                </Button>
              )}
            </div>
          </form>

          {/* Documents Section */}
          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-4">Documents</h3>
            <ul className="space-y-2">
              {data?.identity ? (

                <li className="flex justify-between items-center p-2 bg-gray-100 rounded-md">
                  <span>{data?.identity?.Name}</span>
                  <a href={data?.identity.front?.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">View</a>
                </li>

              ) : (
                <li className="text-gray-500">No documents uploaded.</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
