import { Bar } from "react-chartjs-2";
import { useEffect, useState } from "react";

import { useDispatch } from 'react-redux';
import { chart } from '../redux/authenticated/dashboardSlice';

const TransactionChart = () => {
  const dispatch = useDispatch();

  const [revenue, setRevenue] = useState([]);
  const [registration, setRegistration] = useState([]);

  useEffect(() => {
    dispatch(chart()).then(res => {
      const revenueData = res.payload?.map(data => data.revenue);
      const registrationData = res.payload?.map(data => data.registrations);

      setRevenue(revenueData);
      setRegistration(registrationData);
    }).catch(err => {
      return err
    });

  }, [dispatch])

  return (
    <div className="TransactionChart shadow-[0_4px_10px_rgba(0,0,0,0.03)] p-4 rounded-md bg-white relative overflow-x-auto">
      <div className="revenuecard">
        <div className="flex flex-col mb-5 ">
          <span className="text-sm md:text-xl mb-4">Revenue and Registration</span>
          <span className="text-sm md:text-xl mb-4">
            For Motorist
          </span>
          <span className="text-sm text-light-black">From Jan- Dec</span>
        </div>

        <div className="mx-auto w-[40rem] pb-4">
          <Bar
            data={{
              labels: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
              datasets: [
                {
                  label: "Revenue",
                  data: revenue,
                  backgroundColor: "#5A6ACF",
                },
                {
                  label: "Registration",
                  data: registration,
                  backgroundColor: "#FFC727",
                },
              ],
            }}
          ></Bar>
        </div>
      </div>
    </div>
  );
};

export default TransactionChart;
