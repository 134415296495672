import { useSelector } from 'react-redux';
import DataTable from './DataTable';
import { TopPerformingAgent, setSearchQuery, toggleSelectItem, setSortConfig } from '../redux/authenticated/performingAgentySlice';

function TopPerformingAgentsRecord() {
  const { data, sortConfig, searchQuery, selectedItems, loading } = useSelector((state) => state.performingAgent);

  const tableHeaders = [
    { key: 'fullName', label: 'Full Name' },
    { key: 'phone', label: 'Phone' },
    { key: 'email', label: 'Email' },
    { key: 'role', label: 'Role' },
    { key: 'rank', label: 'Leaderboard' },
  ];

  const renderRow = (item) => (
    <>
      <td className="px-4 py-2">{item.fullName}</td>
      <td className="px-4 py-2">{item.phone}</td>
      <td className="px-4 py-2">{item.email}</td>
      <td className="px-4 py-2">{item.role}</td>
      <td className="px-4 py-2">{item.rank}</td>
    </>
  );

  return (

    <div className="rounded-sm mx-2">
      <span className="text-sm md:text-md lg:text-xl text-black">
        Top Performing Agent
      </span>
      <div className="mt-3">
        <DataTable showPerPage={false} detailpage={false} fetchData={TopPerformingAgent} setSearchQuery={setSearchQuery} toggleSelectItem={toggleSelectItem} setSortConfig={setSortConfig} tableHeaders={tableHeaders} renderRow={renderRow} selectKey={TopPerformingAgent} data={data} sortConfig={sortConfig} searchQuery={searchQuery} selectedItems={selectedItems} loading={loading} showPagination={false} />
      </div>
    </div>
  );
}

export default TopPerformingAgentsRecord;
