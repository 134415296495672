const FilterModal = ({ showModal, statuses, activities, handleStatus, handleActivities, message, onClose, handleExport }) => {
  return (
    <div className={`${showModal ? 'flex' : 'hidden'} fixed overflow-y-auto overflow-x-hidden top-0 right-0 left-0 inset-0 items-center justify-center bg-black bg-opacity-50 z-50`}>
      <div className="bg-light-gray-col rounded-lg shadow relative p-4 py-6 w-full max-w-md max-h-full mx-2">
        <button onClick={onClose} type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-gray-600 hover:text-white">
          <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <div className="space-y-5">
          <h1 className='font-medium text-xl'>Filter</h1>
          <section>
            <h3 className='font-medium mb-4'>Status</h3>

            {statuses.map((status, index) => (
              <button
                key={index}
                onClick={() => handleStatus(index, status.title === 'All' ? '' : status.title)}
                type="button"
                className={`py-2.5 px-5 me-2 mb-2 text-sm font-medium rounded-lg border hover:bg-graey outline-blue focus:z-10 ${status.active ? 'bg-blue text-white' : ''}`}
              >
                {status.title}
              </button>
            ))}
          </section>
          <section>
            <h3 className='font-medium mb-4'>Activities</h3>

            {activities.map((status, index) => (
              <button
                key={index}
                type="button"
                onClick={() => handleActivities(index, status.title)}
                className={`py-2.5 px-5 me-2 mb-2 text-sm font-medium rounded-lg border hover:bg-graey outline-blue focus:z-10 ${status.active ? 'bg-blue text-white' : ''}`}
              >
                {status.title === undefined ? 'All' : status.title || status.title === true ? 'Online' : 'Offline'}
              </button>
            ))}
          </section>
          <section>
            <h3 className='font-medium mb-4'>Download</h3>

            <button
              type="button"
              onClick={() => handleExport()}
              className={`py-2.5 px-5 me-2 mb-2 text-sm font-medium rounded-lg border hover:bg-graey outline-blue focus:z-10 bg-blue text-white`}
            >
              Export Table
            </button>

          </section>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
