import React from 'react';
import '../Loader.css'; // Import the CSS file

const ButtonLoader = () => {
  return (
    <div>
      {/* Preloader */}
      <div className="preloader">
        <div className="preloader-icon p-3"></div>
      </div>
      {/* ./ Preloader */}
    </div>
  );
}

export default ButtonLoader;
