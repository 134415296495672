import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../components/DataTable';
import { MdDelete, MdPause, MdCheckCircle, MdExitToApp } from 'react-icons/md'; // Importing icons
import { format } from 'date-fns'; // Import date-fns for formatting
import { logout } from '../../redux/auth/authSlice';

import { fetchUnion, setCurrentPage, setPerPage, setSearchQuery, toggleSelectItem, setSortConfig, setActiveQuery, setStartDateQuery, setEndDateDateQuery, regUnion } from '../../redux/authenticated/unionSlice';

import Button from '../../components/Button';
import { useState } from 'react';
import ConfirmModal from '../../components/ConfirmModal';
import { approve, suspend, deleted } from '../../redux/authenticated/verification';
import { isRole } from '../../routerGuard/UserRole';
import { Spinal } from '../../assets/icons/extIcons';
import { useNavigate } from 'react-router-dom';

import FetchUser from '../../routerGuard/FetchUser';

const Union = () => {
  document.title = "e-Ticketing | Union";
  const { role } = isRole()

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, sortConfig, searchQuery, currentPage, perPage, totalPages, selectedItems, loading, active, startDate,
    endDate } = useSelector(state => state.union);

  const addedByUsers = FetchUser({ data, idKey: 'addedBy' });  // Reuse FetchUser component
  const approvedByUsers = FetchUser({ data, idKey: 'approvedBy' });  // Reuse FetchUser component
  const suspendedByUsers = FetchUser({ data, idKey: 'suspendedBy' });  // Reuse FetchUser component


  const [toggleModal, setToggleModal] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [roles, setRole] = useState('');
  const [unionName, setUnionName] = useState('');
  const [errors, setErrors] = useState('');

  const tableHeaders = [
    { key: 'fullName', label: 'Full Name' },
    { key: 'userCode', label: 'Unique ID' },
    { key: 'phone', label: 'Phone' },
    { key: 'email', label: 'Email' },
    { key: 'lastLogin', label: 'Last Login' },
    { key: 'addedBy', label: 'Added By' },
    { key: 'registrationStatus', label: 'Status' },
  ];


  // Helper function to format Last Login using date-fns
  const formatLastLogin = (date) => {
    return date ? format(new Date(date), 'PPpp') : 'N/A'; // Date and time format
  };

  // Render buttons for status
  const renderStatusButton = (status, approverId, suspenderId) => {
    let buttonStyle = '';
    let buttonText = '';

    switch (status) {
      case 'approved':
        buttonStyle = 'text-dim-green'; // Green for approved
        buttonText = `Approved by ${approverId || 'N/A'}`;;
        break;
      case 'pending':
        buttonStyle = 'text-yellow'; // Gray for pending
        buttonText = 'Pending';
        break;
      case 'suspended':
        buttonStyle = 'text-red'; // Red for suspended
        buttonText = `Suspended by ${suspenderId || 'N/A'}`;
        break;
      default:
        buttonStyle = 'text-black'; // Default color if no match
        buttonText = 'Unknown';
    }

    return <button className={`py-1 rounded ${buttonStyle}`}> {buttonText} </button>;
  };

  const handleDelete = (_e) => {
    dispatch(deleted(_e)).then(res => {
      if (res.payload !== 200) {
        return false
      } else {
        dispatch(fetchUnion({
          currentPage, perPage, searchQuery, active, startDate,
          endDate
        }));
      }
    }).catch(err => {
      return err
    });
  }

  const handleSuspend = (_e) => {
    dispatch(suspend(_e)).then(res => {
      if (res.payload !== 200) {
        return false
      } else {
        dispatch(fetchUnion({
          currentPage, perPage, searchQuery, active, startDate,
          endDate
        }));
      }
    }).catch(err => {
      return err
    });
  };

  const handleApprove = (_e) => {
    dispatch(approve(_e)).then(res => {
      if (res.payload !== 200) {
        return false
      } else {
        dispatch(fetchUnion({
          currentPage, perPage, searchQuery, active, startDate,
          endDate
        }));
      }
    }).catch(err => {
      return err
    });
  };

  const handleLogout = (_e) => {
    dispatch(logout(_e)).then(res => {
      if (res.payload !== 200) {
        return false
      } else {
        dispatch(fetchUnion({
          currentPage, perPage, searchQuery, active, startDate,
          endDate
        }));
      }
    }).catch(err => {
      return err
    });
  };


  const renderActionButtons = (item) => (
    <div className="flex space-x-2">
      {!item.isDeleted && !['admin', 'manager', 'union', 'user'].includes(role) &&
        <button
          title='Delete'
          className={`bg-red text-white px-3 py-1 rounded`}
          onClick={() => handleDelete(item._id)}
        >
          <MdDelete size={20} />
        </button>
      }
      {item.registrationStatus !== 'suspended' &&
        <button
          title='Suspend'
          className={`bg-yellow text-white px-3 py-1 rounded`}
          onClick={() => handleSuspend(item._id)}
        >
          <MdPause size={20} />
        </button>
      }
      {item.registrationStatus !== 'approved' &&
        <button
          title='Approve'
          className={`bg-dim-green text-white px-3 py-1 rounded`}
          onClick={() => handleApprove(item._id)}
        >
          <MdCheckCircle size={20} />
        </button>
      }
      {item.online &&
        <button
          title='Logout'
          className={`bg-blue text-white px-3 py-1 rounded`}
          onClick={() => handleLogout(item._id)}
        >
          {loading ? <div role="status" className='flex justify-center'> <Spinal /> <span className="sr-only">Loading...</span> </div>
            : <MdExitToApp size={20} />}

        </button>
      }

    </div>
  );

  // Filter the data to only include entries with no status "pending" and avoid userID undefined
  const filteredData = data?.filter(item => item?.userID);

  const handleDetail = (_e) => {
    navigate(`${window.location.pathname === '/' ? 'motorists ' : window.location.pathname}/details`, { state: _e });
  }

  // Row rendering function
  const renderRow = (item, showAll) => (
    <>
      <td className="px-4 py-2 underline" onClick={() => handleDetail(showAll)}>{item?.fullName}</td>
      <td className="px-4 py-2">{item?.userCode}</td>
      <td className="px-4 py-2">{item?.phone}</td>
      <td className="px-4 py-2">{item?.email?.id}</td>
      <td className="px-4 py-2">{formatLastLogin(item?.lastLogin)}</td>
      <td className="px-4 py-2">{addedByUsers[item?.addedBy] || 'N/A'}</td>
      <td className="px-4 py-2">{renderStatusButton(item?.registrationStatus, approvedByUsers[item?.approvedBy], suspendedByUsers[item?.suspendedBy])}</td>
      <td className="px-4 py-2">{renderActionButtons(item)}</td>
    </>
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(regUnion({ email, password, fullName, phone, roles, unionName })).then(res => {
      setErrors(res.payload)
      if (res.payload !== 201) {
        return false
      } else {
        setToggleModal(false)
        dispatch(fetchUnion({
          currentPage, perPage, searchQuery, active, startDate,
          endDate
        }));
      }
    }).catch(err => {
      return err
    });
  }

  return (
    <div className="agents mt-16">
      <div className='flex justify-between'>
        <h3 className='font-semibold text-3xl'>Unions / Partner</h3>
        <div className='w-40'>
          <ConfirmModal show={toggleModal} onClose={() => setToggleModal(false)}>
            <form onSubmit={handleSubmit}>
              {loading && !errors}
              {errors && <p className="text-[#FF4C4C] text-sm">{errors?.message}</p>}
              <div className="flex justify-center space-x-2 mb-6 mt-8">
                <div className='text-left'>
                  <input
                    required
                    placeholder='Email'
                    type="email"
                    autoComplete="off"
                    className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                </div>
                <div className='text-left'>
                  <input
                    required
                    placeholder='Password'
                    type="password"
                    autoComplete="off"
                    className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                    onChange={(e) => setPassword(e.target.value)}
                  ></input>
                </div>
              </div>
              <div className="flex justify-center space-x-2 mb-6 mt-8">
                <div className='text-left'>
                  <input
                    required
                    placeholder='Union / share holders Full Name'
                    type="text"
                    autoComplete="off"
                    className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                    onChange={(e) => setFullName(e.target.value)}
                  ></input>
                </div>
                <div className='text-left'>
                  <input
                    required
                    placeholder='Phone'
                    type="tel"
                    autoComplete="off"
                    className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                    onChange={(e) => setPhone(e.target.value)}
                  ></input>
                </div>
              </div>
              <div className='text-left mb-6 mt-8'>
                <input
                  placeholder='Union Name / Share holders title'
                  type="text"
                  autoComplete="off"
                  className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white w-full"
                  onChange={(e) => setUnionName(e.target.value)}
                ></input>
              </div>
              <div className="flex justify-center space-x-2 mb-6 mt-8">
                <div className='text-left w-full'>
                  <select
                    autoComplete="off"
                    className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white w-full"
                    onChange={(e) => setRole(e.target.value)}
                  >

                    <option value="">Share Holder's Role</option>
                    <option value="keke">Keke</option>
                    <option value="bus">Bus</option>
                    <option value="bike">Bike</option>
                    <option value="government">Government</option>
                    <option value="shareholder">Shareholder</option>
                    <option value="others">Others</option>
                  </select>
                </div>
              </div>

              <Button disabled={loading ? true : false} title={loading ? <div role="status" className='flex justify-center'> <Spinal /> <span className="sr-only">Loading...</span> </div> : 'Submit'} type={'submit'}></Button>
            </form>
          </ConfirmModal>
          <div className='w-40'>
            <button onClick={() => setToggleModal(prevState => !prevState)} className={'bg-blue rounded-md p-4 text-white w-full uppercase text-sm font-medium'}>
              Create User
            </button>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="overflow-x-auto">
          <DataTable fetchData={fetchUnion} setCurrentPage={setCurrentPage} setPerPage={setPerPage} setSearchQuery={setSearchQuery} toggleSelectItem={toggleSelectItem} setSortConfig={setSortConfig} setActiveQuery={setActiveQuery} setStartDateQuery={setStartDateQuery} setEndDateDateQuery={setEndDateDateQuery} tableHeaders={tableHeaders} renderRow={renderRow} selectKey={fetchUnion} data={filteredData} sortConfig={sortConfig} searchQuery={searchQuery} currentPage={currentPage} perPage={perPage} totalPages={totalPages} selectedItems={selectedItems} loading={loading} active={active} startDate={startDate} endDate={endDate} showPagination={true} />
        </div>
      </div>
    </div>
  );
};

export default Union;
