import { Transition, Menu } from "@headlessui/react";
import classNames from "classnames";
import Image from "../assets/image.png";
import Logout from '../routerGuard/LogoutUser';
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate()

  return (
    <div className="header fixed py-4 top-0 z-10 bg-white h-16 flex justify-end items-center w-screen lg:px-10 px-0 shadow-[0_4px_10px_rgba(0,0,0,0.03)]">
      <div className="flex items-center space-x-3">
        <Menu as="div" className="relative">
          <div>
            <Menu.Button className="ml-2 mr-8 lg:mr-0 inline-flex rounded-full focus:outline-none focus:ring-2 focus:ring-black">
              <div
                className="h-10 w-10 rounded-full bg-yellow bg-cover bg-no-repeat bg-center"
                style={{ backgroundImage: `url(${Image})` }}
              >
              </div>
            </Menu.Button>
          </div>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-sm shadow-md p-1 bg-blue ring-1 ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={classNames(
                      active && "bg-yellow w-full text-left",
                      "text-white focus:bg-yellow block cursor-pointer rounded-sm px-4 py-2"
                    )}
                    onClick={() => {
                      navigate('/Profile');
                    }
                    }
                  >
                    Your Profile
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <Logout
                    onLogout={null} // If you want to use the default handleLogout function from the Logout component
                    className={classNames(
                      active && "bg-yellow w-full text-left",
                      "text-white focus:bg-yellow block cursor-pointer rounded-sm px-4 py-2"
                    )}
                  />
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
