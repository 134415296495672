import { FaArrowDown } from "react-icons/fa6";
import { MdOutlineTrendingUp } from "react-icons/md";
import TabLoader from '../assets/tablepre2.gif';
import Datefilter from "../components/DateFilter";
import { addDays } from 'date-fns';

import PaidMotoristChart from "./PaidMotoristChart";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchAllocation, setStartDateQuery, setEndDateDateQuery } from "../redux/authenticated/allocationSlice";
import { dashboard, startDateQuery, endDateDateQuery } from "../redux/authenticated/dashboardSlice";

const AllocationRecord = ({ type, pack, showDateFilter, setShowDateFilter }) => {

  const dispatch = useDispatch();

  const [dashboardRecords, setData] = useState([]);

  const [allocation, setAllocation] = useState(null);

  const [loading, setLoading] = useState(true);

  const [startDate, setPendingStartDate] = useState();
  const [endDate, setPendingEndDate] = useState(addDays(new Date(), 7));

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  useEffect(() => {
    dispatch(fetchAllocation({ startDate, endDate })).then(res => {
      setAllocation(res?.payload[type])
      setLoading(false)
    });

    dispatch(dashboard({ startDate, endDate })).then(res => {
      setData(res?.payload)
      setLoading(false)
    });
  }, [dispatch, type, startDate, endDate]);

  const handleApplyDateFilter = () => {
    // Dispatch the selected date to update the actual filter
    dispatch(setStartDateQuery(startDate));
    dispatch(setEndDateDateQuery(endDate));
    dispatch(startDateQuery(startDate));
    dispatch(endDateDateQuery(endDate));
    setShowDateFilter(false);
  };

  const handleDate = (index) => {
    setPendingStartDate(index.selection.startDate);
    setPendingEndDate(index.selection.endDate);
    setState([index.selection]);
  };

  const checkVehicleType = () => {
    if (pack === 'bike') {
      return dashboardRecords?.totalBike
    } else if (pack === 'keke') {
      return dashboardRecords?.totalKeke
    } else if (pack === 'bus') {
      return dashboardRecords?.totalBus
    } else {
      return null;
    }
  };

  const checkTypes = checkVehicleType();

  const vehicleTypes = [{
    vehicle: pack, percentageIncrease: checkTypes?.percentageIncrease, today: checkTypes?.today, total: checkTypes?.total
  }];

  if (loading) {
    return (
      <div className="flex justify-center items-center h-20">
        <img src={TabLoader} alt="loader" width="10%" className='w-16 h-16' />
      </div>
    )
  }

  return (
    <div className="bg-white rounded-lg px-5 py-3">
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 w-full">
        <div className="py-4 space-y-3 text-gray-400 rounded-md px-4 shadow-[0_4px_10px_rgba(0,0,0,0.03)] flex flex-col justify-center items-center">
          <div className="md:text-xl">Total Compliances</div>
          <div className="">
            <div className="md:text-xl">
              <strong className="text-green-400">{allocation?.totalCompliances ? allocation?.totalCompliances : 0}</strong>
            </div>
          </div>
        </div>

        <Datefilter direction={'right-10 top-44'} showModal={showDateFilter} state={state} handleDate={handleDate} onApply={handleApplyDateFilter} />

        <div className=" py-4 space-y-3 text-gray-400 rounded-md px-4 shadow-[0_4px_10px_rgba(0,0,0,0.03)] flex flex-col justify-center items-center">
          <div className="md:text-xl">Total Non-Compliances</div>
          <div className="">
            <div className="md:text-xl">
              <strong className="text-red">{allocation?.totalNonCompliances ? allocation?.totalNonCompliances : 0}</strong>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 w-full">
        <div className="bg-graey lg:col-span-2 hidden md:flex flex-col justify-center shadow-[0_4px_10px_rgba(0,0,0,0.03)] rounded-lg py-8 px-4">
          <div className="flex flex-col items-center">
            <span className="text-sm md:text-xl mb-3">Total Revenue Monthly </span>
            <span className="text-sm md:text-xl font-semibold">&#8358;{allocation?.totalRevenueReceived ? allocation?.totalRevenueReceived : 0}</span>
          </div>
          <PaidMotoristChart percent={allocation?.monthlyRevenue} />
        </div>

        <div className="md:col-span-1 col-span-2 grid grid-cols-1 gap-4">
          <div className="bg-sky-800 py-4 space-y-3 text-gray-300 rounded-md px-4 shadow-[0_4px_10px_rgba(0,0,0,0.03)] flex flex-col justify-center items-center">
            <div className="md:text-xl">Total Subscription Payment</div>
            <div className="text-sm md:text-2xl font-semibold">&#8358;{allocation?.expectedAmount ? new Intl.NumberFormat("en-NG").format(allocation?.expectedAmount) : 0}</div>
          </div>
          <div className="bg-slate-600 py-4 space-y-3 text-gray-300 rounded-md px-4 shadow-[0_4px_10px_rgba(0,0,0,0.03)] flex flex-col justify-center items-center">
            <div className="md:text-xl">Total Amount to be paid</div>
            <div className="text-sm md:text-2xl font-semibold">&#8358;{allocation?.revenuePaid ? new Intl.NumberFormat("en-NG").format(allocation?.revenuePaid) : 0}</div>
            <div className="flex flex-col space-y-5">
              <div className="flex flex-col items-center space-y-3">
                <div className="tracking-wide text-gray-400 font-semibold text-sm">Percentage Dropdown</div>
                <div className="flex items-center">
                  <FaArrowDown className="text-red mr-3" />
                  <span className="text-red text-sm font-semibold">{allocation?.percentageDropdown ? allocation?.percentageDropdown?.toFixed(3) : 0}%</span>
                </div>
              </div>
              <div className="space-y-3 text-center">
                <div className="tracking-wide text-gray-400 font-semibold text-sm">Amount Difference</div>
                <span className="text-dim-green text-sm font-semibold">&#8358;{allocation?.amountDifference ? new Intl.NumberFormat("en-NG").format(allocation?.amountDifference) : 0}</span>
              </div>
            </div>
          </div>

          {['bike', 'bus', 'keke'].includes(pack) && vehicleTypes.map((item, index) => (
            <div key={index} className="bg-blue py-4 space-y-3 text-gray-300 rounded-md px-4 shadow-[0_4px_10px_rgba(0,0,0,0.03)] flex flex-col justify-center items-center">
              <div className="md:text-xl">Total {item?.vehicle}</div>
              <div className="">
                <div className="md:text-xl">
                  <strong>{item?.total !== undefined ? item?.total : 0}</strong>
                </div>
              </div>
              <div className="flex items-baseline text-gray-300 ">
                <div className="flex items-center justify-between">
                  <MdOutlineTrendingUp className="text-sm" />
                  <span className="ml-2 text-sm">{item?.percentageIncrease !== null ? item?.percentageIncrease?.toFixed(3) : 0}%</span>
                </div>
                <span className="ml-8 text-sm">+ {item?.today !== null ? item?.today : 0} today</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AllocationRecord;