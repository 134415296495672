import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../redux/authenticated/userSlice';

const FetchUser = ({ data, idKey = 'addedBy' }) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState({}); // Store full names based on dynamic ID

  // Function to fetch full names for users by their ID
  const fetchUserById = async (userId) => {
    const response = await dispatch(fetchUser(userId));
    if (response?.payload) {
      setUsers((prev) => ({
        ...prev,
        [userId]: response.payload.fullName,
      }));
    }
  };

  useEffect(() => {
    if (data && idKey) {
      data.forEach((item) => {
        const userId = item.userID ? item.userID[idKey] : item.agentId ? item?.agentId[idKey] : item[idKey];  // Dynamically use the key to get the user ID
        if (userId && !users[userId]) {
          // Fetch only if the full name hasn't been fetched already
          fetchUserById(userId);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, idKey, users]); // Re-run when 'data' or 'idKey' changes

  return users;
};

export default FetchUser;
