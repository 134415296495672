import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axios.service';

// Thunk for fetching top-performing agents
export const TopPerformingAgent = createAsyncThunk(
  'agent/topPerformingAgent',
  async ({ searchQuery }, thunkAPI) => {
    try {
      // Make the API request with pagination and search query
      const response = await axios.get(`/api/performingAgentChart`, {
        params: { search: searchQuery },
      });

      if (response && response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// Define the slice for managing dashboard data
const performingAgentSlice = createSlice({
  name: 'performingAgent',
  initialState: {
    data: [],
    sortConfig: { key: '', direction: 'asc' },
    searchQuery: '',
    
    selectedItems: [],
    loading: false,
    error: null,
    status: 'idle'
  },
  reducers: {
    setSortConfig: (state, action) => {
      state.sortConfig = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    toggleSelectItem: (state, action) => {
      const selectedItem = action.payload;
      if (state.selectedItems.includes(selectedItem)) {
        state.selectedItems = state.selectedItems.filter(item => item !== selectedItem);
      } else {
        state.selectedItems.push(selectedItem);
      }
    },
    clearSelectedItems: (state) => {
      state.selectedItems = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(TopPerformingAgent.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(TopPerformingAgent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload.data; // Data from API
        state.loading = false;
      })
      .addCase(TopPerformingAgent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const {
  setSortConfig,
  setSearchQuery,
  setCurrentPage,
  setPerPage,
  toggleSelectItem,
  clearSelectedItems
} = performingAgentSlice.actions;

export default performingAgentSlice.reducer;
