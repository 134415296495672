import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';

const Datefilter = ({ showModal, state, handleDate, onApply, direction }) => {

  return (
    <section className={`${showModal ? 'flex' : 'hidden'} absolute z-10 ${direction ? direction : 'top-64'}`}>

      <DateRangePicker
        className='shadow-[0_10px_100px_rgba(0,0,0,0.03)] rounded-md'
        onChange={handleDate}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        ranges={state}
        direction="horizontal" 
      />
      <button className='absolute bottom-0 bg-yellow w-32 h-10 m-3 rounded-md text-white shadow-[0_4px_50px_rgba(0,0,0,0.03)]' onClick={onApply}>Apply</button>
    </section>
  )
}

export default Datefilter;