import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IoFilterOutline } from "react-icons/io5";
import { addDays } from 'date-fns';

import Pagination from './Pagination';
import TabLoader from '../assets/tablepre2.gif';
import FilterModal from './FilterModal';
import Datefilter from './DateFilter';

const DataTable = ({
  fetchData,
  tableHeaders,
  renderRow,
  setCurrentPage,
  setPerPage,
  setSearchQuery,
  toggleSelectItem,
  setActiveQuery,
  setStartDateQuery,
  setEndDateDateQuery,
  data,
  searchQuery,
  currentPage,
  perPage,
  totalPages,
  selectedItems,
  loading,
  active,
  startDate,
  endDate,
  showPagination = true, // Optional prop to control pagination visibility
  isNew = true,
  noFilter = true,
  showAll,
  showPerPage = true,
  pending = false
}) => {

  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [showDateFilter, setShowDateFilter] = useState(false);

  const [statuses, setStatuses] = useState([
    { title: 'All', active: true },
    { title: 'Pending', active: false },
    { title: 'Declined', active: false },
    { title: 'Approved', active: false },
    { title: 'Suspended', active: false },
  ]);

  const [activities, setActivities] = useState([
    { title: undefined, active: true },
    { title: true, active: false },
    { title: false, active: false },
  ]);

  const [isAllSelected, setIsAllSelected] = useState(false); // State to track "Select All"

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  const [pendingStartDate, setPendingStartDate] = useState(new Date());
  const [pendingEndDate, setPendingEndDate] = useState(addDays(new Date(), 7));

  // Fetch data on mount or when pagination/search changes
  useEffect(() => {
    dispatch(fetchData({
      currentPage, perPage, searchQuery: pending ? 'pending' : searchQuery, active, startDate,
      endDate
    }));
  }, [currentPage, perPage, searchQuery, active, startDate,
    endDate, dispatch, fetchData, pending]);

  const handleSearch = (e) => {
    const values = e.target.value
    const convertToString = values.toLowerCase()

    dispatch(setSearchQuery(convertToString))
  };

  const handleFilter = () => {
    setShowFilter(prevState => !prevState);
  }

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : ''), obj);
  };

  const handleExport = () => {
    if (!data || data.length === 0) {
      alert("No data available to export.");
      return;
    }

    // Extract headers from tableHeaders prop
    const headers = tableHeaders.map(header => header.label);

    // Prepare CSV content
    let csv = [];
    csv.push(headers.join(",")); // Add headers

    data.forEach(row => {
      const rowData = [];
      tableHeaders.forEach(header => {
        const setNestedValue = () => {
          // If userID exists, return it directly
          if (row?.userID) {
            return row?.userID;
          }
          // If agentId exists, construct the new object by spreading agentId and the nested kyc properties
          else if (row?.agentId) {
            return {
              agent: row?.agentId,
              ...row?.kyc, // Ensure row.kyc exists before spreading
              ...(row?.kyc?.ticketSubscription || {}), // Ensure row.kyc.ticketSubscription exists
              ...(row?.kyc?.userID || {}), // Ensure row.kyc.userID exists
              ...(row?.kyc?.vehicle || {}) // Ensure row.kyc.vehicle exists
            };
          }
          // Return the row if no conditions are met
          else {
            return row;
          }
        };

        let nestedValue = setNestedValue(); // Call setNestedValue to get the correct value
        let cellValue = getNestedValue(nestedValue, header.key); // Use getNestedValue for nested keys

        // Check if the cellValue is an object and process accordingly
        if (typeof cellValue === 'object' && cellValue !== null) {
          if (cellValue.hasOwnProperty('id')) {
            cellValue = cellValue.id;
          } else {
            cellValue = JSON.stringify(cellValue);
          }
        }

        rowData.push(cellValue || ''); // Fallback to empty string if value is missing
      });
      csv.push(rowData.join(","));
    });

    // Create CSV blob and trigger download
    const csvFile = new Blob([csv.join("\n")], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.download = "tableData.csv";
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDateFilter = () => {
    setShowDateFilter(prevState => !prevState);
  }

  const handleStatusClick = (index, _e) => {
    const convertToLowercase = _e.toLowerCase();
    setStatuses((prevStatuses) =>
      prevStatuses.map((status, i) => ({
        ...status, active: i === index, // Only the clicked status becomes active
      }))
    );
    dispatch(setSearchQuery(convertToLowercase));
    setShowFilter(false);
  };

  const handleActivityClick = (index, _e) => {
    setActivities((prevActivities) =>
      prevActivities.map((activity, i) => ({
        ...activity,
        active: i === index, // Only the clicked activity becomes active
      }))
    );
    dispatch(setActiveQuery(_e));
    setShowFilter(false);
  };

  const handleDate = (index) => {
    setPendingStartDate(index.selection.startDate);
    setPendingEndDate(index.selection.endDate);
    setState([index.selection]);
  };

  const handleApplyDateFilter = () => {
    // Dispatch the selected date to update the actual filter
    dispatch(setStartDateQuery(pendingStartDate));
    dispatch(setEndDateDateQuery(pendingEndDate));
    setShowDateFilter(false);
  };

  // Handle "Select All" checkbox click
  const handleSelectAll = () => {
    setIsAllSelected((prev) => !prev);
    data.forEach((item) => {
      dispatch(toggleSelectItem(item?._id));
    });
  };

  // Handle individual row checkbox toggle
  const handleRowSelect = (id) => {
    dispatch(toggleSelectItem(id));
  };

  return (
    <div className="space-y-8">
      <section className="md:flex justify-between">
        <aside className={`${showPagination ? '' : 'hidden'} flex items-center relative`}>
          <input
            type="search"
            placeholder="Search"
            onChange={handleSearch}
            className={`focus:ring-0 h-12 rounded-l-md p-4 w-80 border-0 shadow-[0_4px_10px_rgba(0,0,0,0.03)]`}
          />
          {isNew && (<button className={`bg-white p-4 rounded-r-md cursor-pointer shadow-[0_4px_10px_rgba(0,0,0,0.03)]`} onClick={handleFilter}><IoFilterOutline /></button>
          )}
          <FilterModal showModal={showFilter} onClose={() => setShowFilter(false)} statuses={!noFilter ? [{ title: 'All', active: true }] : statuses} activities={!noFilter ? [] : activities} handleActivities={handleActivityClick} handleStatus={handleStatusClick} handleExport={handleExport} />
          <button className='bg-white p-3 mx-2 space-x-3 rounded-md cursor-pointer flex justify-center items-center shadow-[0_4px_10px_rgba(0,0,0,0.03)]' onClick={handleDateFilter}><span className='text-nowrap'>Date filter</span><IoFilterOutline /></button>
        </aside>
        <Datefilter showModal={showDateFilter} state={state} handleDate={handleDate} onApply={handleApplyDateFilter} />

        {
          showPerPage && <select
            value={perPage}
            onChange={(e) => dispatch(setPerPage(Number(e.target.value)))}
            className="bg-white shadow-[0_4px_10px_rgba(0,0,0,0.03)] h-12 rounded-md px-4 w-80 md:mt-0 mt-3"
          >
            {[5, 10, 20].map((size) => (
              <option key={size} value={size}>
                Show {size} per page
              </option>
            ))}
          </select>
        }
      </section>

      <section className="p-5 rounded-md bg-white shadow-[0_4px_10px_rgba(0,0,0,0.03)] overflow-x-auto overflow-y-auto">
        {loading ? (
          <div className="flex justify-center items-center">
            <img src={TabLoader} alt="loader" className="w-10" />
          </div>
        ) : (
          <table className="min-w-full table-auto">
            <thead>
              <tr>
                <th className='cursor-pointer px-4 py-2'>
                  <input
                    className='scale-125'
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={handleSelectAll}
                  />
                </th>

                {tableHeaders.map((header) => (
                  <th
                    key={header.key}
                    className="cursor-pointer px-4 py-2"
                  >
                    {header.label}{' '}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(data) && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={item._id || index} className='text-nowrap'>
                    <td className="px-4 py-2">
                      <input
                        className='scale-125'
                        type="checkbox"
                        checked={selectedItems.includes(item._id)}
                        onChange={() => handleRowSelect(item._id)}
                      />
                    </td>
                    {renderRow(item.userID ? item.userID : item, showAll = item)}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={tableHeaders.length + 2} className="text-center py-4">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {/* Conditionally render pagination if showPagination is true */}
        {showPagination && (
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={(page) => dispatch(setCurrentPage(page))}
          />
        )}
      </section>
    </div>
  );
};

export default DataTable;
