import { MdOutlineTrendingUp } from "react-icons/md";
import Cash from "../assets/money.png";
import Fluent from "../assets/fluent.png";
import Face from "../assets/face.png";
import Car from "../assets/car.png";
// import { IoFilterOutline } from "react-icons/io5";
import { addDays } from 'date-fns';
import Datefilter from "../components/DateFilter";

import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { dashboard, startDateQuery, endDateDateQuery } from '../redux/authenticated/dashboardSlice';

const DashboardGrid = () => {
  const dispatch = useDispatch();
  const [dashboardRecords, setData] = useState(null);
  const [showDateFilter, setShowDateFilter] = useState(false);

  const [startDate, setPendingStartDate] = useState();
  const [endDate, setPendingEndDate] = useState(addDays(new Date(), 7));

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  useEffect(() => {
    dispatch(dashboard({ undefined })).then(res => {
      setData(res.payload)
    });
  }, [dispatch, startDate, endDate])

  const handleApplyDateFilter = () => {
    // Dispatch the selected date to update the actual filter
    dispatch(startDateQuery(startDate));
    dispatch(endDateDateQuery(endDate));
    setShowDateFilter(false);
  };

  const handleDate = (index) => {
    setPendingStartDate(index.selection.startDate);
    setPendingEndDate(index.selection.endDate);
    setState([index.selection]);
  };

  // const handleDateFilter = () => {
  //   setShowDateFilter(prevState => !prevState);
  // }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full ">
      <div className="absolute right-12 top-20 md:flex space-x-2 hidden">
        {/* <button className='bg-white mx-auto p-3 rounded-md cursor-pointer flex flex-col justify-center items-center shadow-[0_4px_10px_rgba(0,0,0,0.03)]' onClick={handleDateFilter}><div className='text-nowrap'>Date filter</div><IoFilterOutline /></button> */}

        <div className="bg-sky-700 py-2 text-gray-300 rounded-md px-4 shadow-[0_4px_10px_rgba(0,0,0,0.03)]">
          <div className="text-left">
            <div className="md:text-xl">
              <strong>&#8358;{dashboardRecords?.totalPenaltyCharges?.total !== undefined ? new Intl.NumberFormat("en-NG").format(dashboardRecords?.totalPenaltyCharges?.total) : 0}</strong>
            </div>
            <div className="text-sm mb-4">Total Penalty</div>
          </div>
          <div className="flex items-center text-gray-300 ">
            <div className="flex items-center justify-between">
              <MdOutlineTrendingUp className="text-sm" />
              <span className="ml-2 text-sm">{dashboardRecords?.totalPenaltyCharges !== null ? dashboardRecords?.totalPenaltyCharges?.percentageIncrease?.toFixed(3) : 0}%</span>
            </div>
            <span className="ml-8 text-sm">+ {dashboardRecords?.totalPenaltyCharges !== null ? dashboardRecords?.totalPenaltyCharges?.today : 0} today</span>
          </div>
        </div>

        <div className="bg-sky-700 py-2 text-gray-300 rounded-md px-4 shadow-[0_4px_10px_rgba(0,0,0,0.03)]">
          <div className="text-left">
            <div className="md:text-xl">
              <strong>{dashboardRecords?.totalManagers?.total !== undefined ? dashboardRecords?.totalManagers?.total : 0}</strong>
            </div>
            <div className="text-sm mb-4">Approved Managers</div>
          </div>
          <div className="flex items-center text-gray-300 ">
            <div className="flex items-center justify-between">
              <MdOutlineTrendingUp className="text-sm" />
              <span className="ml-2 text-sm">{dashboardRecords?.totalManagers !== null ? dashboardRecords?.totalManagers?.percentageIncrease?.toFixed(3) : 0}%</span>
            </div>
            <span className="ml-8 text-sm">+ {dashboardRecords?.totalManagers !== null ? dashboardRecords?.totalManagers?.today : 0} today</span>
          </div>
        </div>
      </div>

      <div className="overflow-hidden flex-flex-col bg-white py-2 rounded-md px-4 shadow-[0_4px_10px_rgba(0,0,0,0.03)] hover:bg-dim-white hover:bg-opacity-80 ">
        <div className="flex items-center gap-2 h-[6rem] flex-row justify-between mt-4 mb-4">
          <div className="flex flex-col flex-1 text-left">
            <span className="text-sm text-black mb-4">Total Subscription</span>
            <span className="text-sm md:text-xl text-black mb-2">
              <strong>&#8358;{dashboardRecords?.totalPayment?.total !== undefined ? new Intl.NumberFormat("en-NG").format(dashboardRecords?.totalPayment?.total) : 0}</strong>
            </span>
          </div>
          <div className="lg:flex-1 ml-3">
            <img src={Cash} alt="money" width="100%" />
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex items-center justify-between">
            <MdOutlineTrendingUp className="text-dim-green text-sm" />
            <span className="text-black ml-2 text-sm">{dashboardRecords?.totalPayment !== null ? dashboardRecords?.totalPayment?.percentageIncrease?.toFixed(3) : 0}%</span>
          </div>
          <span className="ml-8 text-black text-sm">+ {dashboardRecords?.totalPayment !== null ? dashboardRecords?.totalPayment?.today : 0} today</span>
        </div>
      </div>
      <div className="overflow-hidden flex-flex-col bg-white py-2 rounded-md px-4 shadow-[0_4px_10px_rgba(0,0,0,0.03)] hover:bg-dim-white hover:bg-opacity-80 ">
        <div className="flex items-center gap-2 h-[6rem] flex-row justify-between mt-4 mb-4">
          <div className="flex flex-col flex-1 text-left">
            <span className="text-sm text-black mb-4">Approved Agents</span>
            <span className="text-sm md:text-xl text-black mb-2">
              <strong>{dashboardRecords?.totalAgents?.total !== undefined ? dashboardRecords?.totalAgents?.total : 0}</strong>
            </span>
          </div>
          <div className="lg:flex-1 ml-3">
            <img src={Fluent} alt="fluent" width="100%" />
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex items-center justify-between">
            <MdOutlineTrendingUp className="text-dim-green text-sm" />
            <span className="text-black ml-2 text-sm">{dashboardRecords?.totalAgents !== null ? dashboardRecords?.totalAgents?.percentageIncrease?.toFixed(3) : 0}%</span>
          </div>
          <span className="ml-8 text-black text-sm">+ {dashboardRecords?.totalAgents !== null ? dashboardRecords?.totalAgents?.today : 0} today</span>
        </div>
      </div>
      <div className="overflow-hidden flex-flex-col bg-white py-2 rounded-md px-4 shadow-[0_4px_10px_rgba(0,0,0,0.03)] hover:bg-dim-white hover:bg-opacity-80 ">
        <div className="flex items-center gap-2 h-[6rem] flex-row justify-between mt-4 mb-4">
          <div className="flex flex-col flex-1 text-left">
            <span className="text-sm text-black mb-4">Total Registration</span>
            <span className="text-sm md:text-xl text-black mb-2">
              <strong>{dashboardRecords?.totalRegistration?.total !== undefined ? dashboardRecords?.totalRegistration?.total : 0}</strong>
            </span>
          </div>
          <div className="lg:flex-1 ml-3">
            <img src={Face} alt="face" width="100%" />
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex items-center justify-between">
            <MdOutlineTrendingUp className="text-dim-green text-sm" />
            <span className="text-black ml-2 text-sm">{dashboardRecords?.totalRegistration !== null ? dashboardRecords?.totalRegistration?.percentageIncrease?.toFixed(3) : 0}%</span>
          </div>
          <span className="ml-8 text-black text-sm">+ {dashboardRecords?.totalRegistration !== null ? dashboardRecords?.totalRegistration?.today : 0} today</span>
        </div>
      </div>
      <div className="overflow-hidden flex-flex-col bg-white py-2 rounded-md px-4 shadow-[0_4px_10px_rgba(0,0,0,0.03)] hover:bg-dim-white hover:bg-opacity-80 ">
        <div className="flex items-center gap-2 h-[6rem] flex-row justify-between mt-4 mb-4">
          <div className="flex flex-col flex-1 text-left">
            <span className="text-sm text-black mb-4">Total Vehicles</span>
            <span className="text-sm md:text-xl text-black mb-2">
              <strong>{dashboardRecords?.totalVehicles?.total !== undefined ? dashboardRecords?.totalVehicles?.total : 0}</strong>
            </span>
          </div>
          <div className="lg:flex-1 ml-3">
            <img src={Car} alt="car" width="100%" />
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex items-center justify-between">
            <MdOutlineTrendingUp className="text-dim-green text-sm" />
            <span className="text-black ml-2 text-sm">{dashboardRecords?.totalVehicles !== null ? dashboardRecords?.totalVehicles?.percentageIncrease?.toFixed(3) : 0}%</span>
          </div>
          <span className="ml-8 text-black text-sm">+ {dashboardRecords?.totalVehicles !== null ? dashboardRecords?.totalVehicles?.today : 0} today</span>
        </div>
      </div>

      <Datefilter direction={'right-10 top-44'} showModal={showDateFilter} state={state} handleDate={handleDate} onApply={handleApplyDateFilter} />

    </div>
  );
};

export default DashboardGrid;
