import AllocationRecord from "../../components/AllocationRecord";
import { fetchStakeHolders } from "../../redux/authenticated/allocationSlice";

import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { isRole } from '../../routerGuard/UserRole';
import { IoFilterOutline } from "react-icons/io5";

const AllocationDetail = ({ type, pack }) => {
  const location = useLocation(); // Access the location object
  const { unionRole, role } = isRole();

  const [toggleState, setToggleState] = useState(type);
  const [listType, setListType] = useState([]);
  const [toggleTypes, setToggleTypes] = useState();
  const [showDateFilter, setShowDateFilter] = useState(false);

  const handleDateFilter = () => {
    setShowDateFilter(prevState => !prevState);
  }

  const dispatch = useDispatch();

  const toggleTab = (index, types) => {
    setToggleTypes(types)
    setToggleState(types);
  }

  const data = toggleTypes ?? location.state;

  useEffect(() => {
    dispatch(fetchStakeHolders()).then(res => {
      setListType(res.payload);

      // Check if location.state is valid
      if (location?.state) {
        setToggleState(location.state);
      }
    });
  }, [dispatch, location?.state]);

  const checkUnion = ['shareholder', 'government'].includes(unionRole) || ['super-admin'].includes(role);

  return (
    <div className="mt-16">
      <div className="flex justify-between items-center">
        <div>
          <h3 className='font-semibold text-3xl capitalize'>Allocations to</h3>
          <p className="font-thin text-xl">{data ?? type}</p>
        </div>

        <button className='bg-white p-3 mx-2 space-x-3 rounded-md cursor-pointer flex justify-center items-center shadow-[0_4px_10px_rgba(0,0,0,0.03)]' onClick={handleDateFilter}><span className='text-nowrap'>Date filter</span><IoFilterOutline /></button>
      </div>
      {
        checkUnion &&
        <div className="bloc-tabs flex my-8 overflow-auto">
          {listType?.map((item, index) => (
            <div key={index} className={toggleState === item?.name?.unionName ? "active-tabs rounded-md text-nowrap" : "text-nowrap"} onClick={() => toggleTab(index, item?.name?.unionName)}>{item?.name?.unionName}</div>
          ))}
        </div>
      }

      <div className="content-tabs mt-4">
        {listType?.map((item, index) => (
          <div key={index} className={toggleState === item?.name?.unionName ? "content active-content" : "content"}>
            <AllocationRecord type={item?.name?.unionName} pack={pack} showDateFilter={showDateFilter} setShowDateFilter={setShowDateFilter} />
          </div>
        ))}
      </div>
    </div>
  )
};

export default AllocationDetail;