import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axios.service';

export const approve = createAsyncThunk('verification/approve', async (id, thunkAPI) => {
  try {

    // Check if email or password is empty
    if (!id) {
      return thunkAPI.rejectWithValue({ message: 'User Id is required' });
    }

    // Make the login request with the updated credentials
    const response = await axios.post(`/api/approve/${id}`);
    // Ensure the response has data before returning it
    if (response && response.data) {
      return response.status;
    } else {
      // If response is missing or malformed
      return thunkAPI.rejectWithValue({ message: 'No data in response' });
    }
  } catch (error) {
    // Handle different error cases
    if (error.response) {
      // Server responded with a status other than 2xx
      return thunkAPI.rejectWithValue(error.response.data);
    } else if (error.request) {
      // Request was made, but no response received (e.g., network error)
      return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
    } else {
      // Something else happened
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const suspend = createAsyncThunk('verification/suspend', async (id, thunkAPI) => {
  try {

    // Check if email or password is empty
    if (!id) {
      return thunkAPI.rejectWithValue({ message: 'User Id is required' });
    }

    // Make the login request with the updated credentials
    const response = await axios.post(`/api/suspend/${id}`);
    // Ensure the response has data before returning it
    if (response && response.data) {
      return response.status;
    } else {
      // If response is missing or malformed
      return thunkAPI.rejectWithValue({ message: 'No data in response' });
    }
  } catch (error) {
    // Handle different error cases
    if (error.response) {
      // Server responded with a status other than 2xx
      return thunkAPI.rejectWithValue(error.response.data);
    } else if (error.request) {
      // Request was made, but no response received (e.g., network error)
      return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
    } else {
      // Something else happened
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const deleted = createAsyncThunk('verification/deleted', async (id, thunkAPI) => {
  try {

    // Check if email or password is empty
    if (!id) {
      return thunkAPI.rejectWithValue({ message: 'User Id is required' });
    }

    // Make the login request with the updated credentials
    const response = await axios.post(`/api/deleted/${id}`);
    // Ensure the response has data before returning it
    if (response && response.data) {
      return response.status;
    } else {
      // If response is missing or malformed
      return thunkAPI.rejectWithValue({ message: 'No data in response' });
    }
  } catch (error) {
    // Handle different error cases
    if (error.response) {
      // Server responded with a status other than 2xx
      return thunkAPI.rejectWithValue(error.response.data);
    } else if (error.request) {
      // Request was made, but no response received (e.g., network error)
      return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
    } else {
      // Something else happened
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

const adminSlice = createSlice({
  name: 'verification',
  initialState: {
    data: '',
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(approve.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approve.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(approve.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(suspend.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(suspend.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(suspend.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleted.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleted.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(deleted.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


export default adminSlice.reducer;
