import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import SubscriptionGrid from "../../components/SubscriptionGrid";
import Button from "../../components/Button";
import { regSub, fetchSub, deleteSub, setSearchQuery } from '../../redux/authenticated/subscriptionSlice';
import ConfirmModal from "../../components/ConfirmModal";
import { Spinal } from '../../assets/icons/extIcons';


const Subscription = () => {
  document.title = "e-Ticketing | Subscription";

  const dispatch = useDispatch();

  const formRef = useRef(null);

  const { loading, searchQuery } = useSelector(state => state.subscription)

  const [toggleState, setToggleState] = useState(1);
  const [toggleModal, setToggleModal] = useState(false);
  const [errors, setErrors] = useState('');

  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    dispatch(fetchSub({ searchQuery })).then(res => {
      setSubscription(res.payload.data)
    });
  }, [dispatch, searchQuery])

  const handleSubmitSubscription = async (e) => {
    e.preventDefault();
    dispatch(regSub({ name, price, vehicleType })).then(res => {
      setErrors(res.payload)
      if (res.payload !== 201) {
        return false
      } else {
        setToggleModal(false)
        formRef.current.reset();
        dispatch(fetchSub({ searchQuery })).then(res => {
          setSubscription(res.payload.data)
        });
        dispatch(setSearchQuery(vehicleType));
        setToggleState(vehicleType === 'keke' ? 1 : vehicleType === 'bus' ? 2 : 3);
      }
    }).catch(err => {
      return err
    });
  }

  const toggleTab = (index, types) => {
    dispatch(setSearchQuery(types))
    setToggleState(index);
  }

  const deletePlan = (_e) => {
    dispatch(deleteSub(_e.id));
    dispatch(fetchSub({ searchQuery })).then(res => {
      setSubscription(res.payload.data)
    });
  }

  return (
    <div className="subs">
      <div className="flex items-center justify-between mt-12">

        <div className="bloc-tabs flex">
          <div className={toggleState === 1 ? "active-tabs px-4 rounded-md" : "px-4"} onClick={() => toggleTab(1, 'keke')}>Keke</div>
          <div className={toggleState === 2 ? "active-tabs px-4 rounded-md" : "px-4"} onClick={() => toggleTab(2, 'bus')}>Bus</div>
          <div className={toggleState === 3 ? "active-tabs px-4 rounded-md" : "px-4"} onClick={() => toggleTab(3, 'bike')}>Bike</div>
        </div>

        <div className="w-40">
          <Button callToAction={() => setToggleModal(prevState => !prevState)} title={'Create Plan'}></Button>
        </div>
      </div>
      <div className="content-tabs">
        <div className={toggleState === 1 ? "content active-content" : "content"}>
          <SubscriptionGrid subscription={subscription} loading={loading} deletePlan={deletePlan} />
        </div>
        <div className={toggleState === 2 ? "content active-content" : "content"}>
          <SubscriptionGrid subscription={subscription} loading={loading} deletePlan={deletePlan} />
        </div>
        <div className={toggleState === 3 ? "content active-content" : "content"}>
          <SubscriptionGrid subscription={subscription} loading={loading} deletePlan={deletePlan} />
        </div>
      </div>

      <ConfirmModal show={toggleModal} onClose={() => setToggleModal(false)} >
        <form ref={formRef} onSubmit={handleSubmitSubscription}>
          {loading && !errors}
          {errors && <p className="text-[#FF4C4C] text-sm">{errors?.message}</p>}

          <h2 className="text-xl font-bold mb-4">Create Subscription Plan</h2>
          <div className="flex justify-center space-x-2 mb-6 mt-8">
            <div className='text-left'>
              <select
                required
                autoComplete="off"
                className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                onChange={(e) => setName(e.target.value)}
                value={name}
              >
                <option value="">Select Name</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="3 months">3 Months</option>
                <option value="6 months">6 Months</option>
                <option value="yearly">yearly</option>
              </select>
            </div>
            <div>
              <select
                required
                autoComplete="off"
                className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                onChange={(e) => setVehicleType(e.target.value)}
                value={vehicleType}
              >
                <option value="">Vehicle Type</option>
                <option value="keke">Keke</option>
                <option value="bus">Bus</option>
                <option value="bike">Bike</option>
              </select>
            </div>
            <div className='text-left'>
              <input
                required
                placeholder='Price'
                type="number"
                autoComplete="off"
                className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white w-full"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
              ></input>
            </div>
          </div>
          <Button disabled={loading ? true : false} title={loading ? <div role="status" className='flex justify-center'> <Spinal /> <span className="sr-only">Loading...</span> </div> : 'Submit'} type={'submit'}></Button>

        </form>
      </ConfirmModal>

    </div>
  )
}

export default Subscription;