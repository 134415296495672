import { useSelector } from 'react-redux';
import { format } from 'date-fns'; // Import date-fns for formatting
import DataTable from '../../components/DataTable';
import FetchUser from '../../routerGuard/FetchUser';

import {
  fetchScanHistory,
  setCurrentPage,
  setPerPage,
  setSearchQuery,
  toggleSelectItem,
  setSortConfig,
  setStartDateQuery,
  setEndDateDateQuery,
} from '../../redux/authenticated/scanHistorySlice';

const Transaction = () => {
  document.title = "e-Ticketing | Scan Record";

  const {
    data, sortConfig, searchQuery, currentPage, perPage, totalPages,
    selectedItems, loading, startDate, endDate
  } = useSelector(state => state.scan);

  const addedByUsers = FetchUser({ data, idKey: 'addedBy' });  // Reuse FetchUser component

  const tableHeaders = [
    { key: 'vehicleType', label: 'Vehicle Type' },
    { key: 'subscriptionStatus', label: 'Subscription Status' },
    { key: 'userCode', label: 'Motorist Unique ID' },
    { key: 'subscriptionExpiryDate', label: 'Subscription Expiry Date' },
    { key: 'outstandingPayment', label: 'Outstanding Payment' },
    { key: 'addedBy', label: 'Scanned By' },
    { key: 'createdAt', label: 'Scanned Date' },
  ];

  // Helper function to format Last Login using date-fns
  const formatLastLogin = (date) => {
    return date ? format(new Date(date), 'PPpp') : 'N/A'; // Date and time format
  };

  // Render buttons for status
  const renderStatusButton = (status) => {
    let buttonStyle = '';
    let buttonText = '';

    switch (status) {
      case 'active':
        buttonStyle = 'text-dim-green'; // Green for approved
        buttonText = 'Active';
        break;
      case 'grace':
        buttonStyle = 'text-yellow'; // Gray for pending
        buttonText = 'Grace';
        break;
      case 'expired':
        buttonStyle = 'text-red'; // Red for suspended
        buttonText = 'Expired';
        break;
      default:
        buttonStyle = 'text-black'; // Default color if no match
        buttonText = 'Unknown';
    }

    return <button className={`py-1 rounded ${buttonStyle}`}>{buttonText}</button>;
  };

  // Filter the data to only include entries with no status "pending" and avoid userID undefined
  const filteredData = data?.filter(item => item?.kyc?.userID && item?.agentId);

  // Row rendering function
  const renderRow = (item) => {
    if (!item || !item._id) {
      return null; // Skip rendering if item or item.id is undefined
    }
    return (<>
      <td className="px-4 py-2">{item.kyc?.vehicle?.vehicleType}</td>
      <td className="px-4 py-2">{renderStatusButton(item.kyc?.ticketSubscription?.subscriptionStatus)}</td>
      <td className="px-4 py-2">{item.kyc?.userID?.userCode}</td>
      <td className="px-4 py-2">{formatLastLogin(item.kyc?.ticketSubscription?.subscriptionExpiryDate)}</td>
      <td className="px-4 py-2">₦{item.kyc?.ticketSubscription?.outstandingPayment}</td>
      <td className="px-4 py-2">{addedByUsers[item.agentId?.addedBy] || 'N/A'}</td>
      <td className="px-4 py-2">{formatLastLogin(item?.createdAt)}</td>
    </>)
  };

  return (
    <div className="admins mt-16">
      <div className='flex justify-between'>
        <h3 className='font-semibold text-3xl'>Scan Record</h3>
      </div>

      <div className="mt-5">
        <div className="overflow-x-auto">
          <DataTable
            fetchData={fetchScanHistory}
            setCurrentPage={setCurrentPage}
            setPerPage={setPerPage}
            setSearchQuery={setSearchQuery}
            toggleSelectItem={toggleSelectItem}
            setSortConfig={setSortConfig}
            setStartDateQuery={setStartDateQuery}
            setEndDateDateQuery={setEndDateDateQuery}
            tableHeaders={tableHeaders}
            renderRow={renderRow}
            selectKey={fetchScanHistory}
            data={filteredData}
            sortConfig={sortConfig}
            searchQuery={searchQuery}
            currentPage={currentPage}
            perPage={perPage}
            totalPages={totalPages}
            selectedItems={selectedItems}
            loading={loading}
            startDate={startDate}
            endDate={endDate}
            showPagination={true}
            noFilter={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Transaction;
