import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axios.service';

// Thunk for fetching data
export const regPenalty = createAsyncThunk(
  'penalty/regPenalty',
  async (credentials, thunkAPI) => {
    // Convert email to lowercase
    // List of fields to convert to lowercase
    const fieldsToLowerCase = ['price', 'vehicleType'];

    fieldsToLowerCase.forEach(field => {
      if (credentials[field] && typeof credentials[field] === 'string') {
        credentials[field] = credentials[field].toLowerCase();
      }
    });

    // Validation function to check if fields are empty
    function validateFields(data) {
      const errors = {};

      // Loop through each field and check if it's empty
      Object.keys(data).forEach(field => {
        if (typeof data[field] === 'string') {
          // For strings, check if the trimmed string is empty
          if (!data[field] || data[field].trim() === '') {
            errors[field] = `${field} is required`;
          }
        } else if (data[field] instanceof File) {
          // For files, check if they are undefined or null
          if (!data[field]) {
            errors[field] = `${field} is required`;
          }
        } else {
          // Check for other possible data types (if needed)
          if (!data[field]) {
            errors[field] = `${field} is required`;
          }
        }
      });

      return errors;
    }

    // Perform validation to check for empty fields
    const validationErrors = validateFields(credentials);

    if (Object.keys(validationErrors).length > 0) {
      return thunkAPI.rejectWithValue({ message: 'All field are required' });
    }

    try {
      const response = await axios.post('/api/penalty', credentials);
      // Return the data if the response is successful
      if (response && response.data) {
        return response.status;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {

      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// // Thunk for fetching data
export const fetchPenalty = createAsyncThunk(
  'penalty/fetchPenalty',
  async (thunkAPI) => {

    try {
      // Make the dashboard request with the Authorization header
      const response = await axios.get('/api/penalty');
      // Return the data if the response is successful
      if (response && response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {
      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// // Thunk for fetching data
export const updatePenalty = createAsyncThunk(
  'penalty/updatePenalty',
  async (credentials, thunkAPI) => {

    // Convert email to lowercase
    // List of fields to convert to lowercase
    const fieldsToLowerCase = ['price', 'vehicleType'];

    fieldsToLowerCase.forEach(field => {
      if (credentials[field] && typeof credentials[field] === 'string') {
        credentials[field] = credentials[field].toLowerCase();
      }
    });

    // Validation function to check if fields are empty
    function validateFields(data) {
      const errors = {};

      // Loop through each field and check if it's empty
      Object.keys(data).forEach(field => {
        if (typeof data[field] === 'string') {
          // For strings, check if the trimmed string is empty
          if (!data[field] || data[field].trim() === '') {
            errors[field] = `${field} is required`;
          }
        } else if (data[field] instanceof File) {
          // For files, check if they are undefined or null
          if (!data[field]) {
            errors[field] = `${field} is required`;
          }
        } else {
          // Check for other possible data types (if needed)
          if (!data[field]) {
            errors[field] = `${field} is required`;
          }
        }
      });

      return errors;
    }

    // Perform validation to check for empty fields
    const validationErrors = validateFields(credentials);

    if (Object.keys(validationErrors).length > 0) {
      return thunkAPI.rejectWithValue({ message: 'All field are required' });
    }

    try {
      // Make the dashboard request with the Authorization header
      const response = await axios.patch(`/api/penalty/${credentials.id}`, credentials);
      // Return the data if the response is successful
      if (response && response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {
      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const specificPenalty = createAsyncThunk(
  'data/specificPenalty',
  async (credential, thunkAPI) => {

    try {
      // Make the dashboard request with the Authorization header
      const response = await axios.post(`/api/specificPenalty`, { id: credential.subID, price: credential.price });

      // Return the data if the response is successful
      if (response && response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {
      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const PenaltySlice = createSlice({
  name: 'penalty',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(regPenalty.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(regPenalty.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(regPenalty.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPenalty.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPenalty.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchPenalty.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updatePenalty.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePenalty.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(updatePenalty.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(specificPenalty.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(specificPenalty.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(specificPenalty.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


export default PenaltySlice.reducer;
