import { useSelector } from 'react-redux';
import { format } from 'date-fns'; // Import date-fns for formatting
import DataTable from '../../components/DataTable';
import FetchUser from '../../routerGuard/FetchUser';

import {
  fetchPenaltyHistory,
  setCurrentPage,
  setPerPage,
  setSearchQuery,
  toggleSelectItem,
  setSortConfig,
  setStartDateQuery,
  setEndDateDateQuery,
} from '../../redux/authenticated/paymentHistorySlice';
import { NavLink } from 'react-router-dom';

const PenaltyHistory = () => {
  document.title = "e-Ticketing | Penalty History";

  const {
    penaltyHistory, sortConfig, searchQuery, currentPage, perPage, totalPages,
    selectedItems, loading, startDate, endDate
  } = useSelector(state => state.transaction);

  const addedByUsers = FetchUser({ data: penaltyHistory, idKey: 'addedBy' });  // Reuse FetchUser component

  const tableHeaders = [
    { key: 'bankName', label: 'Bank Name' },
    { key: 'modeOfPayment', label: 'Mode Of Payment' },
    { key: 'uniqueId', label: 'Unique ID' },
    { key: 'name', label: 'Plan Type' },
    { key: 'amount', label: 'Price' },
    { key: 'vehicleType', label: 'Vehiclen Type' },
    { key: 'status', label: 'Status' },
    { key: 'addedBy', label: 'Remitted By' },
    { key: 'createdAt', label: 'Created At' },
  ];

  // Helper function to format Last Login using date-fns
  const formatLastLogin = (date) => {
    return date ? format(new Date(date), 'PPpp') : 'N/A'; // Date and time format
  };

  // Render buttons for status
  const renderStatusButton = (status) => {
    let buttonStyle = '';
    let buttonText = '';

    switch (status) {
      case 'success':
        buttonStyle = 'text-dim-green'; // Green for approved
        buttonText = 'Success';
        break;
      case 'pending':
        buttonStyle = 'text-yellow'; // Gray for pending
        buttonText = 'Pending';
        break;
      case 'failed':
        buttonStyle = 'text-red'; // Red for suspended
        buttonText = 'Failed';
        break;
      default:
        buttonStyle = 'text-black'; // Default color if no match
        buttonText = 'Unknown';
    }
    return <button className={`py-1 rounded ${buttonStyle}`}>{buttonText}</button>;
  };

  // Row rendering function
  const renderRow = (item) => {
    if (!item || !item.id) {
      return null; // Skip rendering if item or item.id is undefined
    }
    return (<>
      <td className="px-4 py-2">{item.bankName}</td>
      <td className="px-4 py-2">{item.modeOfPayment}</td>
      <td className="px-4 py-2">{item.motorist?.uniqueId}</td>
      <td className="px-4 py-2">{item.subscription?.name}</td>
      <td className="px-4 py-2">{item.amount}</td>
      <td className="px-4 py-2">{item.subscription?.vehicleType}</td>
      <td className="px-4 py-2">{renderStatusButton(item.status)}</td>
      <td className="px-4 py-2">{addedByUsers[item.addedBy] || 'N/A'}</td>
      <td className="px-4 py-2">{formatLastLogin(item.createdAt)}</td>
    </>)
  };

  return (
    <div className="admins mt-16">
      <div className='flex justify-between'>
        <h3 className='font-semibold text-3xl'>Penalty Payment History</h3>
        <div className='flex items-center'>
          <div className='w-52'>
            <NavLink to='/penalty' className={'bg-blue rounded-md p-4 text-white w-full uppercase text-sm font-medium'}>
              View Penalty Charges
            </NavLink>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="overflow-x-auto">
          <DataTable
            fetchData={fetchPenaltyHistory}
            setCurrentPage={setCurrentPage}
            setPerPage={setPerPage}
            setSearchQuery={setSearchQuery}
            toggleSelectItem={toggleSelectItem}
            setSortConfig={setSortConfig}
            setStartDateQuery={setStartDateQuery}
            setEndDateDateQuery={setEndDateDateQuery}
            tableHeaders={tableHeaders}
            renderRow={renderRow}
            selectKey={fetchPenaltyHistory}
            data={penaltyHistory}
            sortConfig={sortConfig}
            searchQuery={searchQuery}
            currentPage={currentPage}
            perPage={perPage}
            totalPages={totalPages}
            selectedItems={selectedItems}
            loading={loading}
            startDate={startDate}
            endDate={endDate}
            showPagination={true}
            noFilter={false}
          />
        </div>
      </div>
    </div>
  );
};

export default PenaltyHistory;
