import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { IoSearch } from "react-icons/io5";
import placeholder from "../../assets/face.png";
import { fetchUserCode } from '../../redux/authenticated/userSlice';

import SubscriptionGrid from "../../components/SubscriptionGrid";
import Button from "../../components/Button";
import { specificPenalty, updatePenalty, regPenalty, fetchPenalty } from '../../redux/authenticated/penaltySlice';
import ConfirmModal from "../../components/ConfirmModal";
import { Spinal } from '../../assets/icons/extIcons';


const Penalty = () => {
  document.title = "e-Ticketing | Penalty";

  const dispatch = useDispatch();

  const formRef = useRef(null);

  const { loading } = useSelector(state => state.penalty)

  const [toggleModal, setToggleModal] = useState(false);
  const [toggleUserCode, setToggleUserCode] = useState(false);
  const [errors, setErrors] = useState('');
  const [id, setId] = useState('');
  const [isUpdate, setUpdate] = useState(false);
  const [userCode, setUserCode] = useState('');
  const [subID, setSubID] = useState('');
  const [data, setData] = useState('');

  const [price, setPrice] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    dispatch(fetchPenalty()).then(res => {
      setSubscription(res.payload?.data)
    });
  }, [dispatch]);

  const handleSubmitSubscription = async (e) => {
    e.preventDefault();
    dispatch(regPenalty({ price, vehicleType })).then(res => {
      setErrors(res.payload)
      if (res.payload !== 201) {
        return false
      } else {
        setToggleModal(false);
        formRef.current.reset();
        dispatch(fetchPenalty()).then(res => {
          setErrors('');
          setSubscription(res.payload?.data);
        });
      }
    }).catch(err => {
      return err
    });
  }

  const handleModal = () => {
    setToggleModal(prevState => !prevState);
    setUpdate(false)
  }

  const updatePlan = (_e) => {
    setVehicleType(_e.vehicleType)
    setPrice(_e.price)
    setUpdate(true)
    setId(_e.id)
    setToggleModal(true);
  }

  const submitUpdate = (e) => {
    e.preventDefault();
    dispatch(updatePenalty({ id, price, vehicleType })).then(res => {
      setErrors(res.payload)
      if (res.payload.status !== 201) {
        return false
      } else {
        handleModal()
        formRef.current.reset();
        dispatch(fetchPenalty()).then(res => {
          setErrors('');
          setSubscription(res.payload?.data);
        });
      }
    }).catch(err => {
      return err
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (subID && price) {
      dispatch(specificPenalty({ subID, price })).then(res => {
        if (res.error) {
          setErrors(res.payload);
          return false
        }
        setErrors('');
        setToggleUserCode(false)
      })
    }
  }

  const handleUserCode = (e) => {
    e.preventDefault();

    if (userCode.length < 6) {
      return false
    }

    dispatch(fetchUserCode(userCode)).then(res => {
      if (res.error) {
        setErrors(res.payload);
        return false
      }
      setErrors('');
      setToggleUserCode(true)
      setData(res?.payload);
      setSubID(res?.payload?.ticketSubscription)
    })
  }

  return (
    <div className="subs">
      <div className="flex items-center justify-between mt-12">
        <h3 className='font-semibold text-3xl'>Penalty Charges</h3>

        <div className="w-52">
          <Button callToAction={handleModal} title={'Create Penalty Charge'}></Button>
        </div>
      </div>

      <section className="relative">
        <form action="" onSubmit={handleUserCode} className="flex items-center">
          <input
            type="search"
            placeholder="Motorist Unique ID"
            onChange={(e) => setUserCode(e.target.value)}
            className={`focus:ring-0 h-12 rounded-l-md p-4 w-80 border-0 shadow-[0_4px_10px_rgba(0,0,0,0.03)]`}
          />
          <button type="submit" className={`bg-white p-4 rounded-r-md cursor-pointer shadow-[0_4px_10px_rgba(0,0,0,0.03)]`} ><IoSearch /></button>
        </form>
        <span className={`text-xs capitalize ${errors ? 'text-red' : 'text-gray-400'}`}>{errors ? errors.message : 'alter a specific penalty of any motorist'}</span>

        {toggleUserCode && <div className="absolute top-14 bg-gray-50 border rounded-lg shadow-[0_4px_10px_rgba(0,0,0,0.03)]  p-4 w-full max-w-sm">
          <span className="flex justify-center text-lg bg-gray-200 w-10 ml-auto rounded cursor-pointer hover:bg-gray-300" onClick={() => setToggleUserCode(prev => !prev)}>x</span>
          <div className="flex flex-col items-center pb-10">
            <img
              className="w-24 h-24 mb-3 rounded-full"
              src={data?.portraitSelfie?.url ? data?.portraitSelfie?.url : placeholder}
              alt="user profile pic"
            />
            <h5 className="mb-1 text-xl font-medium text-gray-900">{data?.userID?.fullName}</h5>
            <span className="text-sm text-gray-500">{data?.userID?.email?.id}</span>

            {errors ? <span className="text-sm my-4 text-red">{errors.message}</span> : ''}
            <form className="flex items-center space-x-2 mt-3" onSubmit={handleSubmit}>
              <input
                required
                placeholder='Price'
                type="number"
                autoComplete="off"
                className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white border"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
              ></input>
              <button
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue rounded-lg hover:bg-blue focus:ring-4 focus:outline-none focus:ring-blue"
              >
                Change
              </button>
            </form>
          </div>
        </div>}
      </section>


      <div className="content-tabs mt-8">
        <div className={"content active-content"}>
          <SubscriptionGrid subscription={subscription} loading={loading} deletePlan={updatePlan} Delete={'Update'} color={'border-yellow'} />
        </div>
      </div>

      <ConfirmModal show={toggleModal} onClose={() => setToggleModal(false)} >
        <form ref={formRef} onSubmit={isUpdate ? submitUpdate : handleSubmitSubscription}>
          {loading && !errors}
          {errors && <p className="text-[#FF4C4C] text-sm">{errors?.message}</p>}

          <h2 className="text-xl font-bold mb-4">Create Penalty Charge</h2>
          <div className="flex justify-center space-x-2 mb-6 mt-8">
            <div>
              <select
                required
                autoComplete="off"
                className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white w-full"
                onChange={(e) => setVehicleType(e.target.value)}
                value={vehicleType}
              >
                <option value="">Vehicle Type</option>
                <option value="keke">Keke</option>
                <option value="bus">Bus</option>
                <option value="bike">Bike</option>
              </select>
            </div>
            <div className='text-left'>
              <input
                required
                placeholder='Price'
                type="number"
                autoComplete="off"
                className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
              ></input>
            </div>
          </div>
          <Button disabled={loading ? true : false} title={loading ? <div role="status" className='flex justify-center'> <Spinal /> <span className="sr-only">Loading...</span> </div> : 'Submit'} type={'submit'}></Button>

        </form>
      </ConfirmModal>

    </div>
  )
}

export default Penalty;