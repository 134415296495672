import TabLoader from '../assets/tablepre2.gif';

const SubscriptionGrid = ({ subscription, loading, deletePlan, Delete, color, handleDetail }) => {
  if (loading) {
    return (
      <div className="flex justify-center items-center h-20">
        <img src={TabLoader} alt="loader" width="10%" className='w-16 h-16' />
      </div>
    )
  }

  return (
    <div className="subscriptiongrid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 text-center">
      {Array.isArray(subscription) && subscription.length > 0 ? (
        subscription?.map((item, index) => (
          <div key={index} className="outline outline-1 outline-light-gray-col rounded-lg flex flex-col items-center py-8 hover:bg-dim-white">
            <span className="text-xl mb-8 capitalize underline hover:cursor-pointer" onClick={() => item?.name?.unionName ? handleDetail(item?.name?.unionName) : ''}>{item?.name?.unionName ?? item?.name}</span>
            <span className="text-2xl font-semibold mb-12">{item?.percentage ?? '₦' + item?.price}</span>
            <span></span>
            <button className={`border ${color ?? 'border-red'}  p-2 rounded-md`} onClick={() => deletePlan(item)}>{Delete ?? 'Delete'}</button>
          </div>
        )
        )) : (<h1> No data available</h1>)
      }
    </div>
  )
}
export default SubscriptionGrid;