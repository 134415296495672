export const isTokenExpired = (jwt_decode, token) => {
  if (!token) {
    return true; // If there's no token, it's considered expired.
  }

  try {
    const decodedToken = jwt_decode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decodedToken.exp < currentTime; // Check if the token is expired
  } catch (error) {
    // If the token can't be decoded or there's any other issue, treat it as expired
    return true;
  }
};
