import DashboardGrid from "../../components/DashboardGrid";
import TransactionChart from "../../components/TransactionChart";
import RevenuePieChart from "../../components/RevenuePieChart";
import TopPerformingAgentsRecord from "../../components/TopPerformingAgentsRecord";
import Loader from "../../layouts/Loader";
import { useDispatch } from "react-redux";
import { dashboard } from "../../redux/authenticated/dashboardSlice";
import { useState, useEffect } from "react";
import { MdOutlineTrendingUp } from "react-icons/md";

const Dashboard = () => {
  document.title = "e-Ticketing | Dashboard"
  const dispatch = useDispatch();

  const [dashboardRecords, setData] = useState([]);
  const [loading, setLoading] = useState('true');

  useEffect(() => {
    dispatch(dashboard({ startDate: undefined, endDate: undefined })).then(res => {
      setData(res.payload)
    });

    dispatch(dashboard()).then(() => {
      setLoading(false)
    });
  }, [dispatch])

  const vehicleTypes = [{
    vehicle: 'keke', percentageIncrease: dashboardRecords?.totalKeke?.percentageIncrease, today: dashboardRecords?.totalKeke?.today, total: dashboardRecords?.totalKeke?.total
  }, {
    vehicle: 'bike', percentageIncrease: dashboardRecords?.totalBike?.percentageIncrease, today: dashboardRecords?.totalBike?.today, total: dashboardRecords?.totalBike?.total
  }, {
    vehicle: 'bus', percentageIncrease: dashboardRecords?.totalBus?.percentageIncrease, today: dashboardRecords?.totalBus?.today, total: dashboardRecords?.totalBus?.total
  }];

  return (
    <>
      {loading ?
        <Loader /> :
        <div>
          <div className="flex flex-col md:flex-row items-center justify-between mx-6 lg:mx-2 mt-8">
            <div className="flex flex-col py-6 mt-10 md:mt-6 text-center md:text-left">
              <span className="text-xl md:text-2xl text-black mb-2 font-medium">Welcome Admin</span>
              <span className="text-sm md:text-md text-black">
                Here’s an overview of today’s activities
              </span>
            </div>
          </div>
          <div className="mx-2">
            <DashboardGrid />
          </div>

          <div className="grid grid-cols-2 my-12 lg:grid-cols-3 gap-4 w-full">
            <div className="lg:col-span-2 hidden md:block">
              <TransactionChart />
            </div>

            <div className="md:col-span-1 col-span-2 grid grid-cols-1 gap-4">
              {vehicleTypes.map((item, index) => (
                <div key={index} className="bg-blue py-2 space-y-3 text-gray-300 rounded-md px-4 shadow-[0_4px_10px_rgba(0,0,0,0.03)] flex flex-col justify-center items-center">
                  <div className="md:text-xl">Total {item?.vehicle}</div>
                  <div className="">
                    <div className="md:text-xl">
                      <strong>{item?.total !== undefined ? item?.total : 0}</strong>
                    </div>
                  </div>
                  <div className="flex items-baseline text-gray-300 ">
                    <div className="flex items-center justify-between">
                      <MdOutlineTrendingUp className="text-sm" />
                      <span className="ml-2 text-sm">{item?.percentageIncrease !== null ? item?.percentageIncrease?.toFixed(3) : 0}%</span>
                    </div>
                    <span className="ml-8 text-sm">+ {item?.today !== null ? item?.today : 0} today</span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 w-full ">
            <div className="lg:col-span-2 hidden md:block">
              <TopPerformingAgentsRecord />
            </div>
            <div className="md:col-span-1 col-span-2">
              <RevenuePieChart />
            </div>
          </div>
        </div>
      }
    </>

  );
};

export default Dashboard;
